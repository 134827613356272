import React from "react";
import { TextField } from "@mui/material";
import ModalComponent from "../../atoms/ModalComponent";

function AddEditSiteHostModal({
  addSiteHostModalOpen,
  setAddSiteHostModalOpen,
}) {
  return (
    <ModalComponent
      open={addSiteHostModalOpen}
      submitButtonLabel={"Add Site Host"}
      headerText={"Site Host Registration"}
      handleClose={() => setAddSiteHostModalOpen(false)}
    >
      <TextField
        required
        id="outlined-required"
        label="Site Host Name"
        defaultValue=""
        fullWidth
        margin="normal"
      />
      <TextField
        required
        id="outlined-required"
        label="Email ID"
        defaultValue=""
        fullWidth
        margin="normal"
      />
      <TextField
        id="outlined-required"
        label="Location"
        defaultValue=""
        fullWidth
        margin="normal"
      />
      <TextField
        required
        id="outlined-required"
        label="Mobile No."
        defaultValue=""
        fullWidth
        margin="normal"
      />
    </ModalComponent>
  );
}

export default AddEditSiteHostModal;
