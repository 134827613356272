import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ModalComponent from "../../components/atoms/ModalComponent";
import Barcode from "react-barcode";
import { Print } from "@mui/icons-material";
import { fetchSessionsAPI, fetchTransactionsAPI, fetchOcppLogsAPI } from "../../redux/apis/chargers";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from 'qrcode.react'; // Import QR code component
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import './ChargerDetailsComponent.css'; 
import TextField from "@mui/material/TextField";
import axios from "axios";
import {activateChargerAPI} from "../../redux/apis/chargers";
import { message } from "antd";

const tableStyles = {
  minWidth: 650,
  textTransform: "capitalize",
  "& td, & th": {
    border: "1px solid #4C4E641F !important",
  },
};

const ChargerDetailsContainer = {
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  marginBottom: "1rem",
};

const ChargerInfo = {
  flex: 1,
  minWidth: "200px",
};

const pageStyle = `
@page {
  size: 200mm 200mm; /* Adjust the size as needed */
  margin: 0; /* Remove margins */
}

@media all {
  .pageBreak {
    display: none;
  }
}
@media print {
  .pageBreak {
    page-break-before: always;
  }

  /* Center the content for print */
  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
`;

const ChargerDetailsComponent = ({ selectedCharger, open, handleClose }) => {
  const [transactions, setTransactions] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [ocppLogs, setOcppLogs] = useState([]);
  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsRowsPerPage, setTransactionsRowsPerPage] = useState(10);
  const [sessionsPage, setSessionsPage] = useState(0);
  const [sessionsRowsPerPage, setSessionsRowsPerPage] = useState(10);
  const [ocppLogsPage, setOcppLogsPage] = useState(0);
  const [ocppLogsRowsPerPage, setOcppLogsRowsPerPage] = useState(10);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [sessionsLoading, setSessionsLoading] = useState(false);
  const [ocppLogsLoading, setOcppLogsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // State for tab index
  const [activateChargerForm, setActivateChargerForm] = useState({
    serialNumber: "",
    activationCode: "",
    lat: "",
    lng: ""
  })
 const [isActivateChargerModalOpen, setIsActivateChargerModalOpen] = useState(false);

  useEffect(() => {
    if (open && selectedCharger) {
      // Clear the state before fetching new data
      setTransactions([]);
      setSessions([]);
      setOcppLogs([]);
      fetchChargerData();
      console.log(ocppLogs);
    }
  }, [open, selectedCharger, transactionsPage, transactionsRowsPerPage, sessionsPage, sessionsRowsPerPage, ocppLogsPage, ocppLogsRowsPerPage, tabIndex]);

  const fetchChargerData = async () => {
    setTransactionsLoading(true);
    setSessionsLoading(true);
    setOcppLogsLoading(true);
    try {
      const [transactionResponse, sessionResponse, ocppLogsResponse] = await Promise.all([
        fetchTransactionsAPI(selectedCharger?.id, {
          page: transactionsPage + 1,
          limit: transactionsRowsPerPage,
        }),
        fetchSessionsAPI(selectedCharger?.id, {
          page: sessionsPage + 1,
          limit: sessionsRowsPerPage,
        }),
        fetchOcppLogsAPI(selectedCharger?.id, {
          page: ocppLogsPage + 1,
          limit: ocppLogsRowsPerPage,
        }),
      ]);
      setTransactions(transactionResponse.list || []);
      setSessions(sessionResponse.list || []);
      setOcppLogs(ocppLogsResponse.list || []);
    } catch (error) {
      console.error("Error fetching charger data:", error);
    }
    setTransactionsLoading(false);
    setSessionsLoading(false);
    setOcppLogsLoading(false);
  };

  const barcodeRef = useRef(null); // Reference to the barcode content
  const qrCodeRef = useRef(null); // Reference to the QR code content

  // Setup the print functions using useReactToPrint
  const handlePrintBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handlePrintQRCode = useReactToPrint({
    content: () => qrCodeRef.current,
  });

  const formatSerialNumber = (serialNumber) => {
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    return formattedSerialNumber;
  }

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleActivateCharger = async () => {
    try{
      activateChargerForm.lat = selectedCharger.evseStationLat;
      activateChargerForm.lng = selectedCharger.evseStationLng;
      const response = await activateChargerAPI(activateChargerForm);
    if (response.status === "activated") {
      setIsActivateChargerModalOpen(false);
      message.success("Charger activated successfully");
    }
    } catch (error) {
      message.error("Failed to activate charger");
    }
  }
  
  const openActivateModal = () => {
    setActivateChargerForm({ ...activateChargerForm, serialNumber: selectedCharger.serialNumber });
    setIsActivateChargerModalOpen(true);
  }

  const handleCloseActivateChargerModal = () => {
    setIsActivateChargerModalOpen(false);
  }

  return (
    <>
    <ModalComponent open={open} handleClose={handleClose} headerText={"Charger Details"} showSubmitButton={false}>
      {selectedCharger && (
        <div>
          <div style={ChargerDetailsContainer}>
            <div style={ChargerInfo}>
              <Typography variant="body1">Serial Number: {formatSerialNumber(selectedCharger.serialNumber)}</Typography>
              <Typography variant="body1">ChargeBox Id: {selectedCharger.chargeBoxId}</Typography>
              <Typography variant="body1">Device Name: {selectedCharger.deviceName}</Typography>
              <Typography variant="body1">EVSE Station: {selectedCharger.evseStationAddress}</Typography>
              <Typography variant="body1">Activated: {(selectedCharger.status === "activated") ? "Yes" : "No"}</Typography>
              {(selectedCharger.status === "activated") ? "" : <Typography variant="body1">Activation Code: {selectedCharger.activationCode}</Typography>}
              {(selectedCharger.status === "activated") ? "" :<Button style={{ padding: "0px", marginTop: "10px"}} onClick={openActivateModal}>Activate Charger</Button>}
            </div>
            
            {/* Barcode Section */}
            <div>
              <div
                ref={barcodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="barcodeContainer">
                  <Barcode
                    value={selectedCharger.serialNumber}
                    className="barcodeSvg"
                  />
                </div>
              </div>
              <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Button
                variant="contained"
                style={{ color: "white", gap: "0.5rem", marginTop: "10px", maxHeight: "40px" }}
                color="primary"
                onClick={handlePrintBarcode}
              >
                <Print style={{ color: "#fff" }} />
                Print Barcode
              </Button>
              </Typography>
            </div>
            {/* QR Code Section */}
            <div>
              <div
                ref={qrCodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="qrCodeContainer">
                  <QRCodeSVG
                    value={selectedCharger.serialNumber}
                    size={128}
                    level="H"
                    includeMargin={true}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                style={{ color: "white", gap: "0.5rem" , maxHeight: "40px"}}
                color="primary"
                onClick={handlePrintQRCode}
              >
                <Print style={{ color: "#fff" }} />
                Print QR Code
              </Button>
            </div>
          </div>

          {/* Tabs for Transactions, Sessions, and OCPP Logs */}
          <Box sx={{ width: '100%' }}>
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="Charger Details Tabs">
              <Tab label="Transactions" />
              <Tab label="Sessions" />
              <Tab label="OCPP Logs" />
            </Tabs>
            {tabIndex === 0 && (
              <div>
                <Typography variant="h5" gutterBottom style={{ marginTop: "1rem" }}>
                  Transactions
                </Typography>
                {transactionsLoading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <TableContainer 
                      sx={{
                        maxHeight: "55vh",
                        overflow: "auto",
                        borderRadius: "8px 8px 0 0",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #e0e0e0",
                        backgroundColor: "#ffffff",
                      }}
                    >
                       <Table sx={tableStyles}>
                  <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                  >
                    <TableRow>
                      <TableCell align="center">Sr. No</TableCell>
                      <TableCell align="center">Transaction ID</TableCell>
                      <TableCell align="center">User Name</TableCell>
                      <TableCell align="center">End User ID</TableCell>
                      <TableCell align="center">IN Time</TableCell>
                      <TableCell align="center">OUT Time</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Charging Duration</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                   sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                  >
                    {transactions
                      .slice(transactionsPage * transactionsRowsPerPage, transactionsPage * transactionsRowsPerPage + transactionsRowsPerPage)
                      .map((transaction, index) => (
                        <TableRow key={transaction.id}>
                          <TableCell align="center">{index + 1 + transactionsPage * transactionsRowsPerPage}</TableCell>
                          <TableCell align="center">{transaction.id}</TableCell>
                          <TableCell align="center">{transaction.customerName}</TableCell>
                          <TableCell align="center">{transaction.endCustomerId}</TableCell>
                          <TableCell align="center">{transaction.session.inTime}</TableCell>
                          <TableCell align="center">{transaction.session.outTime}</TableCell>
                          <TableCell align="center">{transaction.transactionID}</TableCell>
                          <TableCell align="center">{transaction.transactionID}</TableCell>
                          <TableCell align="center">{transaction.status}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        overflowX : "hidden", 
                        padding: "0 0.5rem",
                        background: "#f5f3f3",
                        borderTop: "1px solid #e0e0e0",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "0 0 8px 8px",
                      }}
                      count={transactions.totalCount || 0}
                      rowsPerPage={transactionsRowsPerPage}
                      page={transactionsPage}
                      onPageChange={(event, newPage) => setTransactionsPage(newPage)}
                      onRowsPerPageChange={(event) => {
                        setTransactionsRowsPerPage(parseInt(event.target.value, 10));
                        setTransactionsPage(0);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tabIndex === 1 && (
              <div>
                <Typography variant="h5" gutterBottom style={{ marginTop: "1rem" }}>
                  Sessions
                </Typography>
                {sessionsLoading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <TableContainer 
                      sx={{
                        maxHeight: "55vh",
                        overflow: "auto",
                        borderRadius: "8px 8px 0 0",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #e0e0e0",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <Table sx={tableStyles}>
                  <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                  >
                    <TableRow>
                      <TableCell align="center">Sr. No</TableCell>
                      <TableCell align="center">User Name</TableCell>
                      <TableCell align="center">End User ID</TableCell>
                      <TableCell align="center">IN Time</TableCell>
                      <TableCell align="center">OUT Time</TableCell>
                      <TableCell align="center">Total Energy Consumed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody 
                  sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}>
                    {sessions
                      .slice(sessionsPage * sessionsRowsPerPage, sessionsPage * sessionsRowsPerPage + sessionsRowsPerPage)
                      .map((session, index) => (
                        <TableRow key={session.id}>
                          <TableCell align="center">{index + 1 + sessionsPage * sessionsRowsPerPage}</TableCell>
                          <TableCell align="center">{session.customerName}</TableCell>
                          <TableCell align="center">{session.endUserId}</TableCell>
                          <TableCell align="center">{session.inTime}</TableCell>
                          <TableCell align="center">{session.outTime}</TableCell>
                          <TableCell align="center">{session.totalEnergyConsumed}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      sx={{
                        position: "sticky",
                        bottom: 0,
                        overflowX : "hidden", 
                        padding: "0 0.5rem",
                        background: "#f5f3f3",
                        borderTop: "1px solid #e0e0e0",
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "0 0 8px 8px",
                      }}
                      count={sessions.lengtht || 0}
                      rowsPerPage={sessionsRowsPerPage}
                      page={sessionsPage}
                      onPageChange={(event, newPage) => setSessionsPage(newPage)}
                      onRowsPerPageChange={(event) => {
                        setSessionsRowsPerPage(parseInt(event.target.value, 10));
                        setSessionsPage(0);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {tabIndex === 2 && (
              <div>
              <Typography variant="h5" gutterBottom style={{ marginTop: "1rem" }}>
                OCPP Logs
              </Typography>
              {ocppLogsLoading ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <TextField
                    value={ocppLogs.map(log => JSON.stringify(log)).join('\n\n')}
                    multiline
                    fullWidth
                    variant="outlined"
                    rows={15} // Adjust the number of visible rows as needed
                    InputProps={{
                      readOnly: true,
                      style: { 
                        fontFamily: "monospace", 
                        whiteSpace: "pre", 
                        overflowX: "auto", 
                        backgroundColor: "#f5f5f5", 
                        padding: "1rem", 
                        borderRadius: "8px" 
                      }
                    }}
                  />
                )}
              </div>               
            )}
          </Box>
        </div>
      )}
    </ModalComponent>
     <ModalComponent
            open={isActivateChargerModalOpen}
            handleClose={handleCloseActivateChargerModal}
            headerText={"Activate Charger"}
            submitButtonLabel={"Activate"}
            handleSubmit={handleActivateCharger}
          >
            <div>
              <TextField
                label="Serial Number"
                value={activateChargerForm.serialNumber}
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Activation Code"
                value={activateChargerForm.activationCode}
                onChange={(e) => setActivateChargerForm({ ...activateChargerForm, activationCode: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </div>
          </ModalComponent>
    </>
  );
};

export default ChargerDetailsComponent;
