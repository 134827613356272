"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  MenuItem,
  CircularProgress
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  Visibility,
  Search as SearchIcon,
} from "@mui/icons-material";
import ModalComponent from "../../components/atoms/ModalComponent";
import { addSiteHostOperatorAPI, editSiteHostOperatorAPI, deleteSiteHostOperatorAPI } from "../../redux/apis/siteHostoperator";
import ButtonComponent from "../../components/atoms/button/button";
import { fetchUserListAPI } from "../../redux/apis/chargers";
import { fetchSiteHostOperatorAPI } from "../../redux/apis/siteHostoperator";
import SiteHostOperatorMapComponent from "../../components/googleMap/SiteHostOperatorMapComponent";
import { message } from "antd";
import { useSelector } from "react-redux";
import {fetchEvseStationsAPI} from "../../redux/apis/evseStations";

function Sites() {
  const [EvseStationsData, setEvseStationsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalSites, setTotalSites] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSiteHostOperator, setselectedSiteHostOperator] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [siteHostOperatorFormData, setsiteHostOperatorFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    parentUserId: "",
    EvseStationId: ""
  });
  const [EditsiteHostOperatorFormData, setEditsiteHostOperatorFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
  });
  const [siteHostOperatorData, setSiteHostOperatorData] = useState([]);
  const [selectedSiteHost, setSelectedSiteHost] = useState(null);
  const selectedFilter = useSelector(state => state.filter.selectedFilter);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchSiteHostOperatorsData = async () => {
    try {
      setLoading(true);
      const response = await fetchSiteHostOperatorAPI({ 
        filter: searchQuery || (selectedFilter ? `{ "EvseStationId": "${selectedFilter.value}" }` : ""),
        page: page + 1, 
        limit: 500,
      }); 
      setSiteHostOperatorData(response.list || []);
    } catch (error) {
      console.error("Error fetching site host operators:", error);
      // Handle error
    }
    finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSiteHostOperatorsData();
  }, [searchQuery, page, rowsPerPage, selectedFilter]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };


  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleDeleteSite = async (siteId) => {
    try {
      await deleteSiteHostOperatorAPI(siteId);
      fetchSiteHostOperatorsData();
      handleCloseDeleteDialog();
      message.success("Site Host Operator deleted successfully");
    } catch (error) {
      console.error("Error deleting site host operator:", error);
      handleCloseDeleteDialog();
      message.error("Failed to delete site host operator");
    }
  };

  const handleOpenDeleteDialog = (siteId) => {
    setselectedSiteHostOperator(siteId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleAddSiteHostOperator = async () => {
    try {
      const newsiteHostOperatorData = {
        ...siteHostOperatorFormData,
          userType: "site_host",
          userRole: "site_host_operators"
      }
      await addSiteHostOperatorAPI(newsiteHostOperatorData);
      handleCloseAddModal();
      message.success("Site Host Operator Added");
      fetchSiteHostOperatorsData();
    } catch (error) {
      message.error("Failed to add Site Host Operator");
      handleCloseAddModal();
      console.error("Error Adding Site Host Operator:", error);
    }
  };

  const handleOpenEditModal = async (siteHostOperator) => {
    setselectedSiteHostOperator(siteHostOperator);
    setEditsiteHostOperatorFormData({
      firstName: siteHostOperator.firstName,
      lastName: siteHostOperator.lastName,
      mobile: siteHostOperator.phoneNumber,
    });
    setOpenEditModal(true);
  };
  const handleEditSiteHostOperator = async () => {
    try {
      const payload = {
        ...EditsiteHostOperatorFormData,
        userType: "site_host",
        userRole: "site_host_operators"
      };
      await editSiteHostOperatorAPI(selectedSiteHostOperator.id, payload);
      message.success("Site Host Operator Updated");
      handleCloseEditModal();
      fetchSiteHostOperatorsData();
    } catch (error) {
      console.error("Error updating site host operator:", error);
      message.error("Failed to update site host operator");
      handleCloseEditModal();
    }
  };

  const [adminUsers, setAdminUsers] = useState([]);

  // Function to fetch admin users
  const fetchAdminUsers = async () => {
    try {
      const adminUserList = await fetchUserListAPI({
        limit: 500,
        filter: `{ "userRole": "site_host_admin" }`,
      }); 
      setAdminUsers(adminUserList?.list);
    } catch (error) {
      console.error("Error fetching admin users:", error);
    }
  };
  const fetchStationsData = async () => {
    try {
      const response = await fetchEvseStationsAPI({ limit: 500 });
      setEvseStationsData(response.list || []);
    } catch (error) {
      console.error("Error fetching Stations:", error);
    }
  };

  
  const handleOpenMapModal = (site) => {
    setSelectedSiteHost(site);
  };

  const handleCloseMapModal = () => {
    setSelectedSiteHost(null);
  };

  useEffect(() => {
    fetchAdminUsers();
    fetchStationsData();
  }, []);

  
  return (
    <>
    <MainLayout>
      {/* Search and Add Buttons */}
      <Typography
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0rem 0rem 1rem 0rem "}
      >
        <Typography>
          <TextField
            id="search"
            variant="outlined"
            hiddenLabel
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
        </Typography>
        <Typography>
          <ButtonComponent
            label={
              <>
                <Add />{" "}
                <Typography component={"span"}>Add Site Operator</Typography>
              </>
            }
            width={"200px"}
            height={"40px"}
            onClick={() => setOpenAddModal(true)}
          />
        </Typography>
      </Typography>
      {/* Table to Display Sites */}
      {loading ? (
         <div style={{ textAlign: "center" }}>
         <CircularProgress />
       </div>
      ) : (
        <>
          <TableContainer 
          sx={{
            maxHeight: "55vh",
            overflow: "auto",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e0e0e0",
            backgroundColor: "#ffffff",
          }}
          >
            <Table>
              {/* Table Head */}
              <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}>
                <TableRow>
                  <TableCell align="center">Sr. No.</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Mobile</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Evse Stations</TableCell>
                  <TableCell align="center">No. of Chargers</TableCell>
                  <TableCell align="center">Site Host Admin</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {/* Table Body */}
              <TableBody
              sx={{
                "& td": {
                  border: "1px solid #e0e0e0",
                  color: "#555",
                },
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#fafafa",
                },
                "& tr:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}>
                {siteHostOperatorData.map((data, index) => (
                  <TableRow key={data.id}>
                    <TableCell align="center">{index + 1 + rowsPerPage * page}</TableCell>
                    <TableCell align="center">{data?.firstName} {data?.lastName}</TableCell>
                    <TableCell align="center">{data?.phoneNumber}</TableCell>
                    <TableCell align="center">{data?.email}</TableCell>
                    <TableCell align="center">
                    {data?.siteLat && data?.siteLng ? (
                      <a href="javascript:void(0)" onClick={() => handleOpenMapModal(data)} style={{ color: "#007bff" }}>
                        View On Map
                      </a>
                    ) : (
                      "--"
                    )}
                    </TableCell>
                    <TableCell align="center">{data?.evseStationCount}</TableCell>
                    <TableCell align="center">{data?.chargerCount}</TableCell>
                    <TableCell align="center">{data?.siteHostAdminFirstName} {data?.siteHostAdminLastName}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleOpenEditModal(data)}>
                        <Edit sx={{ color: "#5882E6" }} />
                      </IconButton>
                      {/* <IconButton
                        onClick={() => handleOpenDeleteDialog(data.id)}
                      >
                        <Delete />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination */}
          <TablePagination
            component="div"
            sx={{
              position: "sticky",
              bottom: 0,
              overflowX : "hidden", 
              padding: "0 0.5rem",
              background: "#f5f3f3",
              borderTop: "1px solid #e0e0e0",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "0 0 8px 8px",
            }}
            count={totalSites}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <ModalComponent
        open={openAddModal}
        handleClose={handleCloseAddModal}
        headerText="Add Site Host Operator"
        handleSubmit={handleAddSiteHostOperator}
        submitButtonLabel={"Submit"}
      >
        {/* Form for adding a new site */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={siteHostOperatorFormData.firstName}
              onChange={(e) =>
                setsiteHostOperatorFormData({ ...siteHostOperatorFormData, firstName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={siteHostOperatorFormData.lastName}
              onChange={(e) =>
                setsiteHostOperatorFormData({ ...siteHostOperatorFormData, lastName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              label="Email"
              value={siteHostOperatorFormData.email}
              onChange={(e) =>
                setsiteHostOperatorFormData({ ...siteHostOperatorFormData, email: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="mobile"
              label="Mobile No."
              value={siteHostOperatorFormData.mobile}
              onChange={(e) =>
                setsiteHostOperatorFormData({ ...siteHostOperatorFormData, mobile: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="parentUserId"
              select
              label="Site Host Admin"
              value={siteHostOperatorFormData.parentUserId}
              onChange={(e) =>
                setsiteHostOperatorFormData({
                  ...siteHostOperatorFormData,
                  parentUserId: e.target.value,
                })
              }
              fullWidth
            >
              {adminUsers.map((user) => (
                <MenuItem key={user?.id} value={user?.id}>
                  {user?.firstName} {user?.lastName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="EvseStationId"
              select
              label="Station"
              value={siteHostOperatorFormData.EvseStationId}
              onChange={(e) =>
                setsiteHostOperatorFormData({
                  ...siteHostOperatorFormData,
                  EvseStationId: e.target.value,
                })
              }
              fullWidth
            >
              {EvseStationsData.map((station) => (
                <MenuItem key={station?.id} value={station?.id}>
                  {station?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </ModalComponent>
      <ModalComponent
        open={openEditModal}
        handleClose={handleCloseEditModal}
        headerText="Edit Site Host Operator"
        handleSubmit={handleEditSiteHostOperator}
        submitButtonLabel={"Save"}
      >
        {/* Form for editing an existing site */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={EditsiteHostOperatorFormData.firstName}
              onChange={(e) =>
                setEditsiteHostOperatorFormData({ ...EditsiteHostOperatorFormData, firstName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={EditsiteHostOperatorFormData.lastName}
              onChange={(e) =>
                setEditsiteHostOperatorFormData({ ...EditsiteHostOperatorFormData, lastName: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="mobile"
              label="Mobile No."
              value={EditsiteHostOperatorFormData.mobile}
              onChange={(e) =>
                setEditsiteHostOperatorFormData({ ...EditsiteHostOperatorFormData, mobile: e.target.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </ModalComponent>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this site Host Operator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteSite(selectedSiteHostOperator)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {selectedSiteHost && (
        <SiteHostOperatorMapComponent 
          open={Boolean(selectedSiteHost)}
          handleClose={handleCloseMapModal}
          siteHost={selectedSiteHost}
        />
      )}
      </MainLayout>
    </>
  );
}

export default Sites;   
