import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

export const fetchSiteHostOperatorAPI = async (queryParams) => {
  ensureAuthToken();
    try {
      const response = await axiosInstance.get('/site-host-operator', { queryParams });
      return response.data;
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw error;
    }
  };
  
  export const addSiteHostOperatorAPI = async (queryParams) => {
    ensureAuthToken();
      try {
        const response = await axiosInstance.post('/user/register-user', queryParams );
        return response.data;
      } catch (error) {
        console.error('Error Adding site jost operator:', error);
        throw error;
      }
    };
  
    export const editSiteHostOperatorAPI = async (selectedOperatorId , payload) => {
      ensureAuthToken();
        try {
          console.log(selectedOperatorId, payload);
          const response = await axiosInstance.patch(`/user/${selectedOperatorId}`,  payload );
          return response.data;
        } catch (error) {
          console.error('Error updating site host operator:', error);
          throw error;
        }
      };
      export const deleteSiteHostOperatorAPI = async (selectedOperatorId) => {
        ensureAuthToken();
          try {
            const response = await axiosInstance.delete(`/user/${selectedOperatorId}`);
            return response.data;
          } catch (error) {
            console.error('Error deleting site host operator:', error);
            throw error;
          }
        };
