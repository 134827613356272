import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Select from "react-select";
import UploadIcon from "../icons/uploadIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import styles from "./mongodbChartExample.module.scss";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../../utils/constant";
import { BarChart } from "@mui/x-charts";

function AnalyticsMonthlyRevenueChart() {
  return (
    <Grid
      xs={12}
      sm={12}
      md={5.9}
      sx={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 20px #0000000D",
        borderRadius: "20px",
        opacity: "1",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        minHeight: "400px",
        marginTop: "20px",
      }}
    >
      <Typography
        component={"div"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        fontSize={"14px"}
        marginBottom={"0.5rem"}
      >
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={SITE_HOST_OPTIONS}
            placeholder="Site Host"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          Time Period
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={TIME_PERIOD_OPTIONS}
            placeholder="Time Period"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          <UploadIcon />
        </Typography>
      </Typography>
      <Typography
        component={"h1"}
        fontWeight={"500"}
        fontSize={"1.375rem"}
        marginBottom={"1rem"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        Monthly Revenue
      </Typography>
      <Typography>
        <div className={styles.chart_container}>
        <BarChart
            series={[{ data: [35, 44, 24, 34] }]}
            height={400}
            xAxis={[
              {
                data: ["May", "June", "July", "August"],
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            colors={["#073D81"]}
          />
        </div>
      </Typography>
    </Grid>
  );
}

export default AnalyticsMonthlyRevenueChart;
