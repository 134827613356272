import { combineReducers } from "redux";

import counter from "./slices/counter";
import filter from "./slices/filter/filterSlice";


const rootReducer = combineReducers({
  counter,
  filter
});

export default rootReducer;
