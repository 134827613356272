import React, { useState } from "react";
import MainLayout from "../../layout/mainLayout";
import SystemStatus from "../../components/MonitorPageComponents/systemStatus";
import KernelLog from "../../components/MonitorPageComponents/kernelLog";
import FaqSection from "../../components/supportComponents/faqSection";
import Customerquery from "../../components/supportComponents/customerquery";

function Support() {
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("faq");
  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>
      {activeSettingTab === "faq" && <FaqSection />}
      {activeSettingTab === "customer_query" && <Customerquery />}
    </MainLayout>
  );
}

export default Support;
