import React from "react";
import { Button } from "@mui/material";

function ButtonComponent({ label, width, height,onClick }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: " 14px 12px 14px 14px;",
        color: "white",
        textTransform: "capitalize",
        height: height ?? "38px",
        width: width ?? "118px",
        fontSize: "15px",
        marginLeft: "20px",
      }}
    >
      {label}
    </Button>
  );
}

export default ButtonComponent;
