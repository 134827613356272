import React from "react";
import { Modal, Typography, IconButton } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import CloseIcon from '@mui/icons-material/Close';

const MapModalComponent = ({ open, handleClose, charger }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDDiyJAizQKuM93iXy4_2tmpSF_avRL0Cg", 
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "14px",
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ padding: "20px", backgroundColor: "white", margin: "50px auto", borderRadius: "14px", maxWidth: "600px", position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: '10px', top: '10px' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>{'Charge Box Id: ' + charger.chargeBoxId}</Typography>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={{ lat: parseFloat(charger.lat), lng: parseFloat(charger.lng) }}
        >
          <Marker
            position={{ lat: parseFloat(charger.lat), lng: parseFloat(charger.lng) }}
            title={charger.address}
          />
        </GoogleMap>
      </div>
    </Modal>
  );
};

export default MapModalComponent;
