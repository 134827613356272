import * as React from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Expand, ExpandMore } from "@mui/icons-material";
import ChargnexLogo from "../icons/chargnexLogo";

const ProfileMenu = ({ name, role, avatarUrl, onLogout, onMyAccountClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    onLogout();
  };

  const handleMyAccountClick = () => {
    handleMenuClose();
    onMyAccountClick();
  };

  const convertToPascal  = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" fontSize={16} fontWeight={"500"}>
          {convertToPascal(name)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize={14}
          textAlign={"right"}
        >
          {convertToPascal(role)}
        </Typography>
      </Box>
      <Tooltip title="Profile">
        <IconButton onClick={handleMenuOpen}>
          <Avatar alt={name} src={<ChargnexLogo />} />
          <ExpandMore sx={{ color: "#000000" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleMyAccountClick}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Stack>
  );
};

export default ProfileMenu;
