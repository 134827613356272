import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

const ensureAuthToken = () => {
    const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
    if (currentUserId) {
      const tokenKey = `token_${currentUserId}`;
      const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
      setAuthToken(token);
    }
  };

export const fetchSiteHostAdminAPI = async (params) => {
    ensureAuthToken();
      try {
        const response = await axiosInstance.get('/user', {params});
        return response.data;
      } catch (error) {
        console.error('Error fetching sites:', error);
        throw error;
      }
    };
  
export const addSiteHostAdminAPI = async (queryParams) => {
      ensureAuthToken();
        try {
          const response = await axiosInstance.post('/user/register-user', queryParams );
          return response.data;
        } catch (error) {
          console.error('Error adding sites:', error);
          throw error;
        }
      };
    
  