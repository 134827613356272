import React from "react";
import styles from "./secondaryHeader.module.scss";
import { Typography } from "@mui/material";
import Button from "../atoms/button/button";
import TabComponent from "../atoms/tabComponent/tabComponent";
import MonitorTab from "../atoms/tabComponent/MonitorTab";
import AnalyticsTab from "../atoms/tabComponent/analyticsTab";
import SupportTab from "../atoms/tabComponent/supportTab";
import FilterComponent from "../atoms/filterComponent.jsx";
import { useLocation } from 'react-router-dom';


function SecondaryHeader({ setActiveTab }) {

    const  useLocationfromrouter = useLocation();
    const location = useLocationfromrouter.pathname;

    let pageName = "";
    switch(location) {
      case "/charger":
        pageName = "Charger";
        break;
      case "/evse-stations":
        pageName = "EVSE Station";
        break;
      case "/device-management/ota-updates":
          pageName = "OTA Updates";
          break;
    }

  return (location !== "/home" && location !== "/site-host-admin" && location !== "/evse-owners" && location !== "/evse-stations" && location !== "/sites" && location !== "/site-host-operator") && (
    <div className={styles.secondary_header} >
      {location === "/settings" ? (
        <TabComponent {...{ setActiveTab }} />
      ) : location === "/device-management/monitor" ? (
        <MonitorTab {...{ setActiveTab }} />
      ) : location === "/analytics" ? (
        <AnalyticsTab {...{ setActiveTab }} />
      ) : location === "/support" ? (
        <SupportTab {...{ setActiveTab }} />
      ) : (
        <Typography
          component={"div"}
          hidden={location === "/dev-tools" || location}
          // className={styles.secondary_header_filter}
          sx={{
            width: { xs: "100%", sm: "50%", md: "50%" },
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: { sm: "auto" },
            alignItems: { xs: "flex-start", sm: "center" },
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <Typography
            component={"div"}
            fontSize={14}
            textAlign={"left"}
            marginLeft={"15px"}
            marginTop={{ xs: "4px", sm: "0px", md: "0px" }}
            marginRight={"20px"}
          >
            Filter {pageName}:{" "}
          </Typography>
          <Typography>
            <FilterComponent />
          </Typography>
        </Typography>
      )}
    </div>
  );
}

export default SecondaryHeader;
