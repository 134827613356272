import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import { Card } from "../../components/atoms/card";
import {
  Avatar,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  Button,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  CircularProgress
} from "@mui/material";
import CardStyles from "../../components/atoms/card/index.module.scss";
import ButtonComponent from "../../components/atoms/button/button";
import PlanShowModal from "../../components/settingsComponents/planShowModal";
import UserManagementContainer from "../../components/settingsComponents/userManagementContainer";
import axios from "axios";
import ModalComponent from "../../components/atoms/ModalComponent";
import { message } from "antd";
import {fetchUserPaymentAccounts, AddUserPaymentAccount, updateUserPaymentAccount, deleteUserPaymentAccount} from "../../redux/apis/user";


function Settings() {
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("account");

  const [userPaymentAccounts, setUserPaymentAccounts] = useState([]);
  const [formFields, setFormFields] = useState({
    accountNo: "",
    accountType: "",
    bankName: "",
    bankCode: "",
    description: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [loading, setLoading] = useState(false);

  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails);
  const userId = localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser');
  console.log(userId);
  const [OpenPaymentModal, setOpenPaymentModal] = useState(false);

 
  const fetchPaymentAccounts = async () => {
    setLoading(true);
    try {
      if(userDetails.category === "site_host_admin" || userDetails.category === "evse_owner"){
        const response = await fetchUserPaymentAccounts(userId);
        console.log(response);
        setUserPaymentAccounts(response.list);
        setTotalAccounts(response.list.length);
        }
    } catch (error) {
      setLoading(false);
      message.error("Error fetching payment accounts");
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = async () => {
      try {

       await AddUserPaymentAccount(userId, formFields); 
        // Clear the form fields after successful submission
        setFormFields({
          accountNo: "",
          accountType: "",
          bankName: "",
          bankCode: "",
          description: "",
        });
        setOpenPaymentModal(false);
        fetchPaymentAccounts();
        message.success("Payment account added successfully");
      } catch (error) {
        setOpenPaymentModal(false);
        console.error("Error adding payment account", error);
        message.error("Error adding payment account");
      }
  };

  useEffect(() => {
    fetchPaymentAccounts();
    setLoading(false);
  }, []);
  const openPaymentAccountModalOpen = () => {
    setOpenPaymentModal(true);
  };

  const handleCloseAddPaymentAccountModal = () => {
    setOpenPaymentModal(false);
  };

  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>
      {activeSettingTab === "account" && (
        <>
          <Grid
            className={CardStyles.card}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Avatar
              alt={userDetails.firstName}
              src="icons/profile.svg"
              className="ProfileImg"
              sx={{
                background: "black",
                width: "169px",
                height: "169px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                '& img': {
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }
              }}
            />
            <Typography
              sx={{
                marginLeft: "3rem",
                display: "flex",
                flexDirection: "column",
                marginLeft: "4rem",
              }}
            >
              <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                <Typography sx={{ fontWeight: "500" }}>Name :</Typography>
                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                  {`${userDetails.firstName}`}
                </Typography>
              </Typography>
              <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                <Typography sx={{ fontWeight: "500" }}>Email :</Typography>
                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                  {userDetails.email}
                </Typography>
              </Typography>
              <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                <Typography sx={{ fontWeight: "500" }}>Mobile :</Typography>
                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                  {userDetails.phoneNumber || "N/A"}
                </Typography>
              </Typography>
            </Typography>
            <Typography
              display={"flex"}
              flexDirection={"column"}
              marginLeft={"4rem"}
              alignItems={"flex-start"}
              height={"71px"}
            >
              <Typography display={"flex"}>
                <Typography sx={{ fontWeight: "500" }}>Category :</Typography>
                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                  {userDetails.category || "N/A"}
                </Typography>
              </Typography>
              <Typography display={"flex"}>
                <Typography sx={{ fontWeight: "500" }}>
                  Sub Category :
                </Typography>
                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                  admin
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          {(userDetails.category === "site_host_admin" || userDetails.category === "evse_owner") && (
            <>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", width: "100%" }} gap={2}>
            <Typography component={"h1"}
              fontWeight={"500"}
              fontSize={"1.5rem"}>
              User Payment Accounts</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={openPaymentAccountModalOpen}
              style={{ marginTop: "1rem" }}
              sx={{ maxWidth: "200px", color: "#fff" }}
            >
              Add Payment Account
            </Button>
            <ModalComponent
              open={OpenPaymentModal}
              handleClose={handleCloseAddPaymentAccountModal}
              headerText="Add Payment Account"
              handleSubmit={handleSubmit}
              submitButtonLabel="Submit">
              <Grid md={12}>
                <TextField
                  label="Account Number"
                  name="accountNo"
                  value={formFields.accountNo}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid md={12}>
                <TextField
                  label="Account Type"
                  name="accountType"
                  value={formFields.accountType}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid md={12}>
                <TextField
                  label="Bank Name"
                  name="bankName"
                  value={formFields.bankName}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid md={12}>
                <TextField
                  label="Bank Code"
                  name="bankCode"
                  value={formFields.bankCode}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid md={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={formFields.description}
                  onChange={handleFormChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </ModalComponent>
            <Typography
              component={"h2"}
              fontWeight={"500"}
              fontSize={"1.25rem"}
              marginBottom={"0.75rem"}
            >
              Payment Accounts
            </Typography>
            <div>
              <TableContainer
               sx={{
                maxHeight: "65vh",
                borderRadius: "8px 8px 0 0",
                overflow: "auto",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                border: "1px solid #e0e0e0",
                backgroundColor: "#ffffff",
              }}
              >
                <Table>
                  <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                  >
                    <TableRow>
                      <TableCell align = "center">Account Number</TableCell>
                      <TableCell align = "center">Account Type</TableCell>
                      <TableCell align = "center">Bank Name</TableCell>
                      <TableCell align = "center">Bank Code</TableCell>
                      <TableCell align = "center">Description</TableCell>
                      <TableCell align = "center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                  sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                  >
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <CircularProgress size={20} sx={{ margin: "auto" }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      userPaymentAccounts.map((account, index) => (
                        <TableRow key={index}>
                          <TableCell align = "center">{account.accountNo}</TableCell>
                          <TableCell align = "center">{account.accountType}</TableCell>
                          <TableCell align = "center">{account.bankName}</TableCell>
                          <TableCell align = "center">{account.bankCode}</TableCell>
                          <TableCell align = "center">{account.description}</TableCell>
                          <TableCell align = "center"></TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    overflowX : "hidden", 
                    padding: "0 0.5rem",
                    background: "#f5f3f3",
                    borderTop: "1px solid #e0e0e0",
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0 0 8px 8px",
                  }}
                  count={totalAccounts}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
              </div>
          </Grid>
          
          <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }} gap={2}>
            <Grid className={CardStyles.card} md={6}>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"1.25rem"}
                marginBottom={"0.5rem"}
              >
                Subscription Setting
              </Typography>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"14px"}
                marginBottom={"0.75rem"}
              >
                Subscription Plan
              </Typography>
              <Grid sx={{ display: "flex" }}>
                <Grid
                  sx={{
                    background: "#F1F1F1 0% 0% no-repeat padding-box",
                    borderRadius: "14px",
                    opacity: "1",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "500",
                    padding: "0.5rem",
                    width: "50%"
                  }}
                  xs={24}
                >
                  Free Plan
                </Grid>
                <ButtonComponent
                  label={"Upgrade Plan"}
                  width={"50%"}
                  onClick={() => setShowPlanModal(true)}
                ></ButtonComponent>
              </Grid>
            </Grid>
            <Grid className={CardStyles.card} md={6}>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"1.25rem"}
                marginBottom={"0.75rem"}
              >
                Subscription Limit
              </Typography>
              <Grid xs={12} display={"flex"} flexDirection={"row"} >
                <Grid md={4}>
                  <Typography
                    component={"h1"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                    marginBottom={"0.5rem"}
                  >
                    Chargers
                  </Typography>
                  <Grid sx={{ display: "flex" }}>
                    <Grid
                      sx={{
                        background: "#F1F1F1 0% 0% no-repeat padding-box",
                        borderRadius: "14px",
                        opacity: "1",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                        fontWeight: "500",
                      }}
                      xs={12}
                    >
                      2
                    </Grid>
                  </Grid>
                </Grid>
                <Grid md={4}>
                  <Typography
                    component={"h1"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                    marginBottom={"0.5rem"}
                  >
                    Users
                  </Typography>
                  <Grid sx={{ display: "flex" }}>
                    <Grid
                      sx={{
                        background: "#F1F1F1 0% 0% no-repeat padding-box",
                        borderRadius: "14px",
                        opacity: "1",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "500",
                        padding: "10px",
                      }}
                      xs={12}
                    >
                      2
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4}>
                <Typography
                  component={"h1"}
                  fontWeight={"500"}
                  fontSize={"14px"}
                  my={"0.5rem"}
                >
                  Groups
                </Typography>
                <Grid sx={{ display: "flex" }}>
                  <Grid
                    sx={{
                      background: "#F1F1F1 0% 0% no-repeat padding-box",
                      borderRadius: "14px",
                      opacity: "1",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                      fontWeight: "500",
                    }}
                    xs={12}
                  >
                    2
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
            </>
        )}

          {/* Reimbursement */}

          <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }} gap={2}>
            <Grid className={CardStyles.card} md={6}>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"1.25rem"}
                marginBottom={"0.5rem"}
              >
                Address
              </Typography>
              <Grid sx={{ display: "flex" }}>
                <Grid
                  sx={{
                    background: "#F1F1F1 0% 0% no-repeat padding-box",
                    borderRadius: "14px",
                    opacity: "1",
                    alignItems: "center",
                    display: "flex",
                    padding: "1rem",
                    fontWeight: "500",
                  }}
                  xs={12}
                >
                  2305 Guenette St, Saint-Laurent, Quebec H4R 2E9, Canada
                </Grid>
              </Grid>
            </Grid>
            <Grid className={CardStyles.card} md={6}>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"1.25rem"}
                marginBottom={"0.5rem"}
              >
                Reimbursement
              </Typography>
              <Grid sx={{ display: "flex" }}>
                <Grid
                  sx={{
                    background: "#F1F1F1 0% 0% no-repeat padding-box",
                    borderRadius: "14px",
                    opacity: "1",
                    alignItems: "center",
                    display: "flex",
                    padding: "1rem",
                    fontWeight: "500",
                  }}
                  xs={12}
                >
                  <Grid
                    xs={12}
                    display={"flex"}
                    flexWrap={"wrap"}
                    gap={1}
                    alignItems={"end"}
                  >
                    <Grid xs={4}>
                      <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                        $ 8000
                      </Typography>
                      <Typography component={"span"} sx={{ fontSize: "13px" }}>
                        Monthly Limit
                      </Typography>
                    </Grid>
                    <Grid xs={7}>
                      <Typography>
                        <LinearProgress
                          value={76}
                          variant="determinate"
                          sx={{
                            height: "9px",
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            borderRadius: "5px",
                            opacity: 1,
                          }}
                        />
                      </Typography>
                      <Typography component={"span"} sx={{ fontSize: "13px" }}>
                        Monthly Limit
                      </Typography>
                    </Grid>
                    <Grid xs={4}>
                      <Typography component={"span"} sx={{ fontSize: "13px" }}>
                        Approved
                      </Typography>
                      <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                        $ 8000
                      </Typography>
                    </Grid>
                    <Grid xs={7}>
                      <Typography component={"span"} sx={{ fontSize: "13px" }}>
                        Pending
                      </Typography>
                      <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                        $ 8000
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Permissions */}

          <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }}>
            <Grid className={CardStyles.card} xs={12}>
              <Typography
                component={"h1"}
                fontWeight={"500"}
                fontSize={"1.25rem"}
                marginBottom={"0.5rem"}
              >
                Permissions
              </Typography>
              <Grid sx={{ display: "flex" }}>
                <Grid
                  sx={{
                    background: "#F1F1F1 0% 0% no-repeat padding-box",
                    borderRadius: "14px",
                    opacity: "1",
                    alignItems: "center",
                    padding: "1rem",
                    fontSize: "1rem",
                    display: "flex",
                  }}
                  xs={12}
                  container
                >
                  <Grid xs={6}>Add EVSE Owner</Grid>
                  <Grid xs={6}>Add EVSE Owner</Grid>
                  <Grid xs={6}>Add EVSE Owner</Grid>
                  <Grid xs={6}>Add EVSE Owner</Grid>
                  <Grid xs={6}>Add EVSE Owner</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {showPlanModal && (
            <PlanShowModal
              showPlanModal={showPlanModal}
              setShowPlanModal={setShowPlanModal}
            />
          )}
        </>
      )}
      {activeSettingTab === "user_management" && <UserManagementContainer />}
    </MainLayout>
  );
}

export default Settings;
