import * as React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import ProcessTab from "./processTab";
import ServiceTab from "./serviceTab";

function Process() {
  const [value, setValue] = React.useState("processes");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="white"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab
          value="processes"
          label="Processes"
          sx={{ textTransform: "capitalize" }}
        />
        <Tab
          value="services"
          label="Services"
          sx={{ textTransform: "capitalize" }}
        />
      </Tabs>
      <Typography component={"div"}>
        {value === "processes" && <ProcessTab />}
        {value === "services" && <ServiceTab />}
      </Typography>
    </Box>
  );
}

export default Process;
