import React from "react";

function ChargerLocationPinIcon({ width, height, color }) {
  return (
    <svg
      id="Group_462"
      data-name="Group 462"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.257 14.703"
    >
      <g id="noun-bolt-3536771" transform="translate(3.621 2.33)">
        <path
          id="Path_4711"
          data-name="Path 4711"
          d="M26.989,7.179,25.6,10.193a.114.114,0,0,1-.1.07h-.023a.118.118,0,0,1-.093-.116V8.292H24.1A.114.114,0,0,1,24,8.24.108.108,0,0,1,24,8.13l1.391-3.014a.11.11,0,0,1,.127-.064.118.118,0,0,1,.093.116V7.023h1.275a.114.114,0,0,1,.1.052A.1.1,0,0,1,26.989,7.179Z"
          transform="translate(-23.986 -5.05)"
          fill={color}
        />
      </g>
      <g id="noun-location-1168520" transform="translate(0)">
        <g id="Group_459" data-name="Group 459" transform="translate(0 0)">
          <path
            id="Path_4714"
            data-name="Path 4714"
            d="M65.128,13a5.134,5.134,0,0,1,5.128,5.128c0,2.253-2.972,6.662-4.6,9.2-.575.719-.959.192-1.054.1-1.63-2.636-4.6-7.237-4.6-9.3A5.134,5.134,0,0,1,65.128,13Zm0,1.725a3.307,3.307,0,1,1-3.355,3.307A3.314,3.314,0,0,1,65.128,14.725Z"
            transform="translate(-60 -13)"
            fill={color}
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
}

export default ChargerLocationPinIcon;
