import React from "react";

function ChargerDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.553"
      height="24.606"
      viewBox="0 0 38.553 34.606"
    >
      <g
        id="noun-ev-charging-station-5577787"
        transform="translate(-12.083 -15.965)"
      >
        <g
          id="Group_457"
          data-name="Group 457"
          transform="translate(12.083 15.965)"
        >
          <path
            id="Path_4708"
            data-name="Path 4708"
            d="M49.1,30.567,45.83,25.044a1.531,1.531,0,0,0-.2-.266,6.633,6.633,0,0,0-4.823-2.091H36.1v-5.2a1.525,1.525,0,0,0-1.525-1.525H16.027A1.525,1.525,0,0,0,14.5,17.49v30.03h-.894a1.525,1.525,0,1,0,0,3.05H36.957a1.525,1.525,0,1,0,0-3.05H36.1V46.7h5.361v2.342a1.525,1.525,0,0,0,1.525,1.525h2.68a1.525,1.525,0,0,0,1.525-1.525V46.557a4.579,4.579,0,0,0,3.447-4.429V36.363a11.716,11.716,0,0,0-1.523-5.771ZM17.552,47.5V19.016H33.047V47.5H17.552Zm30.034-5.376a1.527,1.527,0,0,1-1.525,1.525H36.1V25.738h4.71A3.573,3.573,0,0,1,43.3,26.755l3.168,5.354a8.66,8.66,0,0,1,1.12,4.255v5.765Z"
            transform="translate(-12.083 -15.965)"
            fill="#FF0100"
          />
          <circle
            id="Ellipse_51"
            data-name="Ellipse 51"
            cx="2.821"
            cy="2.821"
            r="2.821"
            transform="translate(27.641 19.148)"
            fill="#FF0100"
          />
          <path
            id="Path_4710"
            data-name="Path 4710"
            d="M28.373,34.64h9.335a.508.508,0,0,0,.508-.508V27.967a.508.508,0,0,0-.508-.508H28.373a.508.508,0,0,0-.508.508v6.164A.508.508,0,0,0,28.373,34.64Z"
            transform="translate(-19.842 -21.616)"
            fill="#FF0100"
          />
          <path
            id="Path"
            d="M.364,0V5.1"
            transform="translate(12.836 17.303)"
            fill="#FF0100"
            stroke="#FF0100"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
          <path
            id="Path-2"
            data-name="Path"
            d="M.364.365h0"
            transform="translate(12.836 27.136)"
            fill="#FF0100"
            stroke="#FF0100"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
          />
        </g>
      </g>
    </svg>
  );
}

export default ChargerDownIcon;
