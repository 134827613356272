import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Add, Edit, Search } from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import AddEditSiteHostModal from "../../components/siteHostComponents/addEditSiteHostModal/addEditSiteHostModal";

function UserManagementContainer() {
  const [addSiteHostModalOpen, setAddSiteHostModalOpen] = useState(false);

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = [
    {
      sr_no: 1,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    {
      sr_no: 2,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    {
      sr_no: 3,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    {
      sr_no: 4,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    {
      sr_no: 5,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
  ];

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "14px" }}>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"1rem 2rem 1rem 2rem "}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>ADD SITE HOST</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={() => setAddSiteHostModalOpen(true)}
            />
          </Typography>
        </Typography>

        <Table
          sx={{ minWidth: 650, textTransform: "capitalize" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              "& td, & th": { border: "1px solid #4C4E641F !important" },
              background: "#F5F3F3 0% 0% no-repeat padding-box",
            }}
          >
            <TableRow>
              <TableCell align="center">Sr No.</TableCell>
              <TableCell align="left">Site Host Name</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Email ID</TableCell>
              <TableCell align="left">Mobile Number</TableCell>
              <TableCell align="left">No. Of Chargers</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Date Of Registraion</TableCell>
              <TableCell align="left">Created By</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.sr_no}
                sx={{
                  "& td, & th": { border: "1px solid #4C4E641F !important" },
                }}
              >
                <TableCell align="center">{row.sr_no}</TableCell>
                <TableCell align="left">{row.site_host}</TableCell>
                <TableCell align="left">{row.location}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.mo_no}</TableCell>
                <TableCell align="left">{row.no_of_chargers}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.created_by}</TableCell>
                <TableCell>
                  <Typography>
                    <Edit onClick={() => window.print(row.calories)} />
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {addSiteHostModalOpen && (
        <AddEditSiteHostModal
          addSiteHostModalOpen={addSiteHostModalOpen}
          setAddSiteHostModalOpen={setAddSiteHostModalOpen}
        />
      )}
    </>
  );
}

export default UserManagementContainer;
