import { AppBar, Typography } from "@mui/material";
import React, { useState } from "react";
import TermsModal from "./termsModal";
import PrivacyPolicyModal from "./privacyPolicyModal";

function Footer() {
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termModalOpen, setTermModalOpen] = useState(false);
  return (
    <AppBar
      sx={{
        height: "2.5rem",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: { sm: "column", md: "row" },
        justifyContent: "space-between",
        alignItems: "center",
        px: "1.5rem",
        py: "1rem",
        position:"absolute",
        top:"auto",
        bottom:0,
        marginBottom:"0px !important"
      }}
    >
      <Typography fontSize={"14px"}>
        @Chargnex. 2024 All Rights Reserved
      </Typography>
      <Typography fontSize={"14px"}>
        <Typography
          component={"span"}
          fontSize={"14px"}
          sx={{ cursor: "pointer" }}
          onClick={() => setTermModalOpen(true)}
        >
          Terms and Conditions
        </Typography>{" "}
        |
        <Typography
          component={"span"}
          fontSize={"14px"}
          sx={{ cursor: "pointer" }}
          onClick={() => setPrivacyModalOpen(true)}
        >
          {" "}
          Privacy Policy
        </Typography>{" "}
        | Disclaimer
      </Typography>
      <Typography fontSize={"14px"}>A division of Trunexa</Typography>
      {termModalOpen && (
        <TermsModal
          termModalOpen={termModalOpen}
          setTermModalOpen={setTermModalOpen}
        />
      )}
      {privacyModalOpen && (
        <PrivacyPolicyModal
          privacyModalOpen={privacyModalOpen}
          setPrivacyModalOpen={setPrivacyModalOpen}
        />
      )}
    </AppBar>
  );
}

export default Footer;
