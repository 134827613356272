import React, { useState } from "react";
import MainLayout from "../../layout/mainLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import ModalComponent from "../../components/atoms/ModalComponent";
import siteHostData from "../../utils/Chargnex.sitehosts.json"

function SiteHosts() {
  const [siteHosts, setSiteHosts] = useState(siteHostData);

  const [addSiteHostModalOpen, setAddSiteHostModalOpen] = useState(false);
  const [editSiteHostModalOpen, setEditSiteHostModalOpen] = useState(false);
  const [selectedSiteHost, setSelectedSiteHost] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleAddSiteHost = (newSiteHost) => {
    newSiteHost.sr_no = siteHosts.length + 1;
    setSiteHosts([...siteHosts, newSiteHost]);
    setAddSiteHostModalOpen(false);
  };

  const handleEditSiteHost = (editedSiteHost) => {
    const updatedSiteHosts = siteHosts.map((siteHost) =>
      siteHost.sr_no === editedSiteHost.sr_no ? editedSiteHost : siteHost
    );
    setSiteHosts(updatedSiteHosts);
    setEditSiteHostModalOpen(false);
    setSelectedSiteHost(null);
  };

  const handleDeleteSiteHost = (sr_no) => {
    const updatedSiteHosts = siteHosts.filter(
      (siteHost) => siteHost.sr_no !== sr_no
    );
    setSiteHosts(updatedSiteHosts);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AddEditSiteHostModal = ({
    addSiteHostModalOpen,
    setAddSiteHostModalOpen,
    handleAddSiteHost,
    handleEditSiteHost,
    siteHost, // Pass the selected site host for editing
  }) => {
    const [siteHostData, setSiteHostData] = useState(
      siteHost || {
        sr_no: "", // Handle editing
        site_host: "",
        location: "",
        email: "",
        mo_no: "",
      }
    );

    console.log("data",siteHostData)

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setSiteHostData({ ...siteHostData, [name]: value });
    };

    const handleSubmit = () => {
      if (siteHost) {
        handleEditSiteHost(siteHostData); // Edit existing site host
      } else {
        handleAddSiteHost(siteHostData); // Add new site host
      }
    };

    return (
      <ModalComponent
        open={addSiteHostModalOpen}
        submitButtonLabel={siteHost ? "Edit Site Host" : "Add Site Host"} // Change button label for editing
        headerText={siteHost ? "Edit Site Host" : "Add Site Host"} // Change header text for editing
        handleClose={() => setAddSiteHostModalOpen(false)}
        handleSubmit={handleSubmit}
      >
        <TextField
          required
          name="site_host"
          label="Site Host Name"
          fullWidth
          margin="normal"
          value={siteHostData.site_host}
          onChange={handleInputChange}
        />
        <TextField
          required
          name="email"
          label="Email ID"
          fullWidth
          margin="normal"
          value={siteHostData.email}
          onChange={handleInputChange}
        />
        <TextField
          name="location"
          label="Location"
          fullWidth
          margin="normal"
          value={siteHostData.location}
          onChange={handleInputChange}
        />
        <TextField
          required
          name="mo_no"
          label="Mobile No."
          fullWidth
          margin="normal"
          value={siteHostData.mo_no}
          onChange={handleInputChange}
        />
      </ModalComponent>
    );
  };

  return (
    <MainLayout>
      <TableContainer component={Paper} sx={{ borderRadius: "14px" }}>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"1rem 2rem 1rem 2rem "}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>ADD SITE HOST</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={() => setAddSiteHostModalOpen(true)}
            />
          </Typography>
        </Typography>

        <Table
          sx={{ minWidth: 650, textTransform: "capitalize" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              "& td, & th": { border: "1px solid #4C4E641F !important" },
              background: "#F5F3F3 0% 0% no-repeat padding-box",
            }}
          >
            <TableRow>
              <TableCell align="center">Sr No.</TableCell>
              <TableCell align="center">Site Host Name</TableCell>
              <TableCell align="center">Location</TableCell>
              <TableCell align="center">Email ID</TableCell>
              <TableCell align="center">Mobile Number</TableCell>
              <TableCell align="center">No. Of Chargers</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Date Of Registration</TableCell>
              <TableCell align="center">Created By</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteHosts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.sr_no}
                  sx={{
                    "& td, & th": {
                      border: "1px solid #4C4E641F !important",
                    },
                  }}
                >
                  <TableCell align="center">{row.sr_no}</TableCell>
                  <TableCell align="left">{row.site_host}</TableCell>
                  <TableCell align="left">{row.location}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.mo_no}</TableCell>
                  <TableCell align="left">{row.no_of_chargers}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                  <TableCell align="left">{row.created_by}</TableCell>
                  <TableCell>
                    <Typography>
                      <Edit
                        onClick={() => {
                          setSelectedSiteHost(row);
                          setEditSiteHostModalOpen(true);
                        }}
                      />
                      <Delete onClick={() => handleDeleteSiteHost(row.sr_no)} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={siteHosts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {addSiteHostModalOpen && (
        <AddEditSiteHostModal
          addSiteHostModalOpen={addSiteHostModalOpen}
          setAddSiteHostModalOpen={setAddSiteHostModalOpen}
          handleAddSiteHost={handleAddSiteHost}
        />
      )}
      {editSiteHostModalOpen && (
        <AddEditSiteHostModal
          addSiteHostModalOpen={editSiteHostModalOpen}
          setAddSiteHostModalOpen={setEditSiteHostModalOpen}
          handleEditSiteHost={handleEditSiteHost}
          siteHost={selectedSiteHost}
        />
      )}
    </MainLayout>
  );
}

export default SiteHosts;
