import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Select, FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Add, Assignment} from "@mui/icons-material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid, message } from "antd";
import moment from "moment";
import Pusher from 'pusher-js';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete
} from "@mui/material"; // Import Dialog for confirmation popup
import ButtonComponent from "../../components/atoms/button/button";
import ModalComponent from "../../components/atoms/ModalComponent";
import ChargerLocationMapComponent from "../../components/googleMap/ChargerLocationMapComponent";
import {
  fetchChargerList,
  registerChargerAPI,
  assignChargerAPI,
  editChargerAPI,
  deleteChargerAPI,
  fetchConnectorTypeAPI,
  fetchUserListAPI,
  fetchSiteListAPI,
  generateSerialNumberAPI
} from "../../redux/apis/chargers";
import { getData } from 'country-list';
import {fetchEvseStationsAPI} from "../../redux/apis/evseStations";
import {fetchSiteHostAdminAPI} from "../../redux/apis/siteHostAdmin";
import ChargerDetailsComponent from "../../components/chargerDetails/chargerDetailsComponent";
import { useSelector } from "react-redux";

function Chargers() {

  const GenerateButtonStyles = {
    marginBottom: "10px",
    background: "#2D9CDB",
    color: "#FFFFFF",
    textTransform: "none",
    fontSize: "16px",
  };
  
  //Fetch country data
  const countryData = getData();
  const [chargers, setChargers] = useState([]);
  const [allChargers, setAllChargers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalChargers, setTotalChargers] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false); // State for edit modal
  const [selectedChargerforMap, setSelectedChargerforMap] = useState(null);
  const [newChargerData, setNewChargerData] = useState({
    serialNumber: "",
    connectorTypeId: "",
    deviceName: "",
    evseOwnerId: "",
    chargerModel: "",
    connectorPair: "",
    country: ""
  });
  const [assignChargerData, setAssignChargerData] = useState({
    serialNumber: "",
    evseStationId: "",
  });
  const [selectedChargerId, setSelectedChargerId] = useState(""); // State to store selected charger ID for edit
  const [loading, setLoading] = useState(false); // State variable to track loading
  const [siteHostAdmins, setSiteHostAdmins] = useState([]);
  const selectedFilter = useSelector(state => state.filter.selectedFilter);
  const [evseStations, setEvseStations] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedSiteHostAdminId, setSelectedSiteHostAdminId] = useState("");
  const [statusFilter, setStatusFilter] = useState('');
  const [chargingStatusFilter, setChargingStatusFilter] = useState('');
  const [regDateSort, setRegDateSort] = useState("desc");
  const [selectedSerialno, setSelectedSerialno] = useState("");
   

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //fetching site host admin data
  const fetchSiteHostAdminData = async () => {
    try {
      const response = await fetchSiteHostAdminAPI({
        limit: 500,
        filter: `{ "userRole": "site_host_admin" }`,
      });
      setSiteHostAdmins(response.list || []);
    } catch (error) {
      console.error("Error fetching site host admins:", error);
    } 
  };

  
  const fetchChargers = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await fetchChargerList({
        filter:  searchQuery
        || (selectedFilter ? `{ "EvseStationId": "${selectedFilter.value}" }` : "")
        || (statusFilter ? `{ "status": "${statusFilter}" }` : "")
        || (chargingStatusFilter ? `{ "chargingStatus": "${chargingStatusFilter}" }` : ""),
        page: page + 1,
        sort: `registeredAt:${regDateSort}`,
        limit: rowsPerPage,
      });
      setChargers(response.list || []);
      setTotalChargers(response.totalCount || 0);
    } catch (error) {
      console.error("Error fetching chargers:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const fetchAllChargers = async () => {
    try {
      const response = await fetchChargerList({ limit: 10000 }); // Adjust limit as needed
      setAllChargers(response.list || []); // Save all chargers for autocomplete
    } catch (error) {
      console.error("Error fetching all chargers:", error);
    } 
  };

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    // Bind to the event
    channel.bind('charging-status-update', (data) => {
      console.log(data)
      // updateChargerStatus(data.locationData.all);
    });

    // Initial fetch
    fetchChargers();

    
  }, []);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    // Bind to the 'charger-registered' event
    channel.bind('charger-registered', (data) => {
      console.log('New Charger Registered:', data);

      // Fetch chargers data again when the event is triggered
      fetchChargers();
    });

    // Clean up on unmount
    return () => {
      channel.unbind('charger-registered');
      pusher.unsubscribe('pusher-node-app');
    };
  }, []);

 

  const updateChargerStatus = (updatedChargers) => {
    setChargers((prevChargers) =>
      prevChargers.map((charger) => {
        const updatedCharger = updatedChargers.find((uc) => uc.id === charger.id);
        return updatedCharger ? { ...charger, ...updatedCharger } : charger;
      })
    );
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenAddModal = () => {
    setNewChargerData({
      serialNumber: "",
      deviceName: "",
      connectorTypeId: "",
      evseOwnerId: "",
      chargerModel: "",
      connectorPair: "",
      country: ""
    });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    fetchChargers();
  };

  const handleOpenAssignModal = (charger) => {
    setAssignChargerData({
      evseStationId: charger.evseStationId || '',
      serialNumber: charger.serialNumber || '',
    });
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setAssignChargerData({
      serialNumber: "",
      evseStationId: "",
    })
    fetchChargers();
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleAddCharger = async () => {
    try {
      const cleanedData = { ...newChargerData, serialNumber: newChargerData.serialNumber.replace(/-/g, '') };
    
      // Conditionally set/remove evseOwnerId only if it's set
    if (!cleanedData.evseOwnerId) {
      delete cleanedData.evseOwnerId;
    }
      await registerChargerAPI(cleanedData);
      message.success("Charger Added");
      handleCloseAddModal();
    } catch (error) {
      console.error("Error adding charger:", error);
      message.error(error?.error);
    }
  };

  const handleAssignCharger = async () => {
    try {
      const res = await assignChargerAPI({
        serialNumber: assignChargerData?.serialNumber,
        evseStationId: assignChargerData?.evseStationId,
      });
      message.success("Charger Assigned");
      handleCloseAssignModal();
    } catch (error) {
      console.error("Error assigning charger:", error);
      message.error(error?.error);
    }
  };

  const handleOpenEditModal = (chargerId) => {
    setSelectedChargerId(chargerId); // Set the selected charger ID for edit
    // Find the charger object with the corresponding ID
    const chargerToEdit = chargers.find((charger) => charger.id === chargerId);
    // Set the existing charger data to prefill the modal fields
    setNewChargerData({
      serialNumber: chargerToEdit.serialNumber,
      deviceName: chargerToEdit.deviceName,
      connectorTypeId: chargerToEdit.connectorTypeId,
      evseOwnerId: chargerToEdit.evseOwnerId,
      
    });
    setOpenEditModal(true);
  };

  const handleUpdateCharger = async () => {
    try {
      // Call your API to update the charger data with the newChargerData
      // Replace this with your actual API call
      const res = await editChargerAPI(selectedChargerId, newChargerData);
      handleCloseEditModal();
      message.success("Charger Updated");
      fetchChargers();
    } catch (error) {
      console.error("Error updating charger:", error);
      message.error(error?.error);
    }
  };

  const generateSerialNumber = async () => {
    const response = await generateSerialNumberAPI();
    const serialNumber = response.serialNumber;
    
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    setNewChargerData({ ...newChargerData, serialNumber: formattedSerialNumber });
  };

  const formatSerialNumber = (serialNumber) => {
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    return formattedSerialNumber;
  }

  const handleNewChargerInputChange = (event) => {
    const { name, value } = event.target;
    setNewChargerData({ ...newChargerData, [name]: value });

  };

  const handleAssignChargerInputChange = (event) => {
    const { name, value } = event.target;
    setAssignChargerData({
      ...assignChargerData,
      [name]: value,
    });
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = (chargerId) => {
    setSelectedChargerId(chargerId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteChargerAPI(selectedChargerId);
      handleCloseDeleteDialog();
      message.success("Charger Deleted");
      fetchChargers(); // Refresh the chargers list after deleting
    } catch (error) {
      console.error("Error deleting charger:", error);
      message.error(error?.error);
    }
  };

  const [selectedCharger, setSelectedCharger] = useState(null); // State to store selected charger for view modal

  const [connectorTypesData, setConnectorTypesData] = useState([]); // State variable to store connector types data
  const [evseOwnersData, setEvseOwnersData] = useState([]);

  const fetchConnectorTypes = async () => {
    try {
      // Fetch connector types data from the API
      const response = await fetchConnectorTypeAPI();
      setConnectorTypesData(response.list || []); // Set connector types data in state
    } catch (error) {
      console.error("Error fetching connector types:", error);
    }
  };

  const fetchEvseOwners = async () => {
    try {
      const response = await fetchUserListAPI({
        limit: 500,
        filter: `{ "userRole": "evse_owner" }`,
      }); // Replace fetchUserListAPI with your actual API function
      setEvseOwnersData(response?.list);
    } catch (error) {
      console.error("Error fetching EVSE owners:", error);
      // Handle error
    }
  };


  useEffect(() => {
    fetchChargers();
  }, [selectedFilter, statusFilter, chargingStatusFilter,regDateSort, page, rowsPerPage, searchQuery]);
  const handleClearFilter = (setter) => () => {
    setter('');
  };


  const fetchEvseStations = async (selectedSiteHostAdminId) => {
    try {
      const response = await fetchEvseStationsAPI({
        filter: `{ "siteHostAdminId": "${selectedSiteHostAdminId}" }`,
        limit: 500,
      }); // Replace fetchEvseStationsAPI with your actual API function
      setEvseStations(response.list || []);
    } catch (error) {
      console.error("Error fetching EVSE stations:", error);
    }
  };

  const [selectedChargersDetails, setSelectedChargersDetails] = useState({});

   // Function to open view modal and fetch charger data
   const handleViewCharger = (charger) => {
    setSelectedCharger(charger); // Set the selected charger for view modal
    setSelectedChargersDetails({
      "charger":charger,
      "open":true
    })
  };

  const handleCloseMapModal = () => {
    setSelectedChargerforMap(null);
  };
  const handleOpenMapModal = (site) => {
    setSelectedChargerforMap(site);
  };

  useEffect(() => {
    // Fetch connector types data
    fetchConnectorTypes();
  }, []);

  useEffect(() => {
    fetchEvseOwners();
    fetchAllChargers();
  }, []);

  useEffect(() => {
    if (selectedSiteHostAdminId) {
      fetchEvseStations(selectedSiteHostAdminId);
    }
  }, [selectedSiteHostAdminId]);
  
  useEffect(() => {
    fetchSiteHostAdminData();
  },[]);

  useEffect(() => {
    fetchEvseStations();
  }, [selectedSiteId]);

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleToggleSort = () => {
    setRegDateSort((prev) => (prev === "asc" ? "desc" : "asc"));
    // You can trigger your data sorting logic here
  };


  return (
    <>
      <MainLayout>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem"}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add /> <Typography component={"span"}>Add Charger</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={handleOpenAddModal}
            />
            <ButtonComponent
              label={<Typography component={"span"}>Assign</Typography>}
              // width={"200px"}
              height={"40px"}
              onClick={handleOpenAssignModal}
              style={{
                marginLeft: 10,
                backgroundColor: "#D6D6DB",
                color: "black",
              }}
            />
          </Typography>
        </Typography>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer 
            sx={{
              maxHeight: "55vh",
              overflow: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
            >
              <Table
                sx={{ minWidth: 650, textTransform: "capitalize"}}
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TableRow
                    sx={{
                      "& td, & th": {
                        border: "1px solid #4C4E641F !important",
                      },
                    }}
                  >
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">Charge Box ID</TableCell>
                    <TableCell align="center">EVSE Station</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Last Active</TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Button
                          onClick={handleToggleSort}
                          sx={{ 
                           
                            color: "#333", // White text for active, dark grey for default
                            borderRadius: "8px", // Softer radius
                            fontSize: "13px",
                            fontWeight: "500",
                            padding: "4px 8px", // Adjust padding for smaller height
                            minHeight: "32px", // Reduce the minimum height
                            ".MuiSelect-select": {
                              padding: "4px 8px", // Adjust padding inside the select
                            }
                          }}
                        >
                          Reg. Date
                          {regDateSort === "asc" ? (
                            <ArrowUpwardIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                          ) : (
                            <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                          )}
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center" >
                       <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <Select
                            value={statusFilter}
                            onChange={handleFilterChange(setStatusFilter)}
                            sx={{ 
                              color: "#333", // White text for active, dark grey for default
                              borderRadius: "8px", // Softer radius
                              fontSize: "13px",
                              fontWeight: "500",
                              padding: "4px 8px", // Adjust padding for smaller height
                              minHeight: "32px", // Reduce the minimum height
                              ".MuiSelect-select": {
                                padding: "4px 8px", // Adjust padding inside the select
                              },
                              "fieldset": {
                                border: "none"}
                            }}
                            displayEmpty
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: "#f5f5f5", // Background color of dropdown
                                  color: "#000", // Text color of dropdown items
                                  maxHeight: "200px", // Limit height for the dropdown
                                  borderRadius: "8px", // Softer radius for dropdown
                                },
                              },
                            }}
                          >
                            <MenuItem value="">Status</MenuItem>
                            <MenuItem value="registered">Registered</MenuItem>
                            <MenuItem value="activated">Activated</MenuItem>
                            <MenuItem value="available">Available</MenuItem>
                            <MenuItem value="busy">Busy</MenuItem>
                            <MenuItem value="offline">Offline</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                          </Select>
                          {statusFilter && (
                            <IconButton 
                              size="small" 
                              onClick={handleClearFilter(setStatusFilter)} 
                              sx={{
                                marginLeft: "8px",
                                border: "1px solid #ff3232" ,
                                color: "#616161",
                                borderRadius: "50%", // Round button
                                width: "16px", 
                                height: "16px",
                                "&:hover": {
                                  borderColor: "#ff5b5b" 
                                }
                              }}
                            >
                              <CloseIcon fontSize="small" sx={{height: "10px", color: "#ff3232", "&:hover": {color: "#ff5b5b"}}} />
                            </IconButton>
                          )}
                       </div>
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex", alignItems: "center", border: "none", justifyContent: "center" }}>
                        <Select
                        value={chargingStatusFilter}
                        onChange={handleFilterChange(setChargingStatusFilter)}
                        sx={{
                          color: "#333", // White text for active, dark grey for default
                          borderRadius: "8px", // Softer radius
                          fontSize: "13px",
                          fontWeight: "500",
                          padding: "4px 8px", // Adjust padding for smaller height
                          minHeight: "32px", // Reduce the minimum height
                          ".MuiSelect-select": {
                            padding: "4px 8px", // Adjust padding inside the select
                          },
                          "fieldset": {
                            border: "none"}
                        }}
                        displayEmpty
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor: "#f5f5f5", // Background color of dropdown
                              color: "#000", // Text color of dropdown items
                              maxHeight: "200px", // Limit height for the dropdown
                            },
                          },
                        }}
                        >
                        <MenuItem value="">Charging Status</MenuItem>
                        <MenuItem value="available">Available</MenuItem>
                        <MenuItem value="preparing">Preparing</MenuItem>
                        <MenuItem value="charging">Charging</MenuItem>
                        <MenuItem value="suspendedEVSE">Suspended EVSE</MenuItem>
                        <MenuItem value="suspendedEV">Suspended EV</MenuItem>
                        <MenuItem value="finishing">Finishing</MenuItem>
                        <MenuItem value="reserved">Reserved</MenuItem>
                        <MenuItem value="unavailable">Unavailable</MenuItem>
                        <MenuItem value="faulted">Faulted</MenuItem>
                      </Select>
                      {chargingStatusFilter && (
                        <IconButton 
                          size="small" 
                          onClick={handleClearFilter(setChargingStatusFilter)} 
                          sx={{
                            marginLeft: "8px",
                            border: "1px solid #ff3232" ,
                            color: "#616161",
                            borderRadius: "50%", // Round button
                            width: "16px", 
                            height: "16px",
                            "&:hover": {
                              borderColor: "#ff5b5b" 
                            }
                          }}
                        >
                          <CloseIcon fontSize="small" sx={{height: "10px", color: "#ff3232", "&:hover": {color: "#ff5b5b"}}} />
                        </IconButton>
                      )}
                        </div>
                    </TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody 
                sx={{
                  "& td": {
                    border: "1px solid #e0e0e0",
                    color: "#555",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#fafafa",
                  },
                  "& tr:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
                >
                  {chargers.map((charger, index) => (
                    <TableRow key={charger.id}>
                      <TableCell
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleViewCharger(charger)}
                        align="center"
                      >
                        {formatSerialNumber(charger.serialNumber)}
                      </TableCell>
                      <TableCell align="center" sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => handleViewCharger(charger)}>{charger?.chargeBoxId}</TableCell>
                      <TableCell align="center">
                        {charger?.evseStationName===null?<Button onClick={()=>handleOpenAssignModal(charger) } sx={{textDecoration:"underline", color:"#007BFF"}}>Assign EVSE</Button>:charger?.evseStationName}
                      </TableCell>
                      <TableCell align="center">
                      {charger.lat && charger.lng ? (
                      <Button onClick={() => handleOpenMapModal(charger)} sx={{color:"#007bff", textDecoration:"underline", textAlign:"center"}}>
                        View On Map
                      </Button>
                    ) : (
                      "--"
                    )}
                      </TableCell>
                      <TableCell align="center">
                      {charger?.lastHeartbeat ? moment(charger.lastHeartbeat).format("DD MMM YYYY") : '--'}
                    </TableCell>
                      <TableCell align="center">
                      {charger?.registeredAt ? moment(charger.registeredAt).format("DD MMM YYYY") : '--'}
                    </TableCell>
                      <TableCell align="center">
                        {charger?.registeredByUserFirstName}{" "}
                        {charger?.registeredByUserLastName}
                      </TableCell>
                      <TableCell align="center">{charger?.status}</TableCell>
                      <TableCell align="center">{charger?.chargingStatus}</TableCell>
                      <TableCell align="center">
                        <Typography sx={{ display: "flex" }}>
                        <IconButton
                          onClick={() => handleOpenEditModal(charger?.id)} // Implement edit charger action
                        >
                          <EditIcon style={{ color: "#5882E6" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDeleteDialog(charger.id)} // Implement delete charger action
                        >
                          <DeleteIcon style={{ color: "#DF6465" }} />
                        </IconButton>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              sx={{
                position: "sticky",
                bottom: 0,
                overflowX : "hidden", 
                padding: "0 0.5rem",
                background: "#f5f3f3",
                borderTop: "1px solid #e0e0e0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0 0 8px 8px",
              }}
              count={totalChargers}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
            <ModalComponent
        open={openAddModal}
        handleClose={handleCloseAddModal}
        headerText={"Add Charger"}
        handleSubmit={handleAddCharger}
        submitButtonLabel={"Submit"}
      >
        <TextField
          id="serialNumber"
          name="serialNumber"
          label="Serial Number"
          variant="outlined"
          fullWidth
          value={newChargerData.serialNumber}
          onChange={handleNewChargerInputChange}
          style={{ marginBottom: 10 }}
        />
        <Button onClick={generateSerialNumber} style={GenerateButtonStyles}>Generate Serial Number</Button>
        <TextField
          id="deviceName"
          name="deviceName"
          label="Device Name"
          variant="outlined"
          fullWidth
          value={newChargerData.deviceName}
          onChange={handleNewChargerInputChange}
          style={{ marginBottom: 10 }}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Connector Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="connectorTypeId"
            name="connectorTypeId"
            value={newChargerData.connectorTypeId}
            label="Connector Type"
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          >
            {connectorTypesData?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Evse Owner</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="evseOwnerId"
            name="evseOwnerId"
            value={newChargerData.evseOwnerId}
            label="Evse Owner"
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          >
            {evseOwnersData.map((owner) => (
              <MenuItem key={owner.id} value={owner.id}>
                {owner.firstName} {owner.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="chargerModel"
          name="chargerModel"
          label="Charger Model"
          variant="outlined"
          fullWidth
          value={newChargerData.chargerModel}
          onChange={handleNewChargerInputChange}
          style={{ marginBottom: 10 }}
        />
         <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
            <Select
                id="country"
                name="country"
                label="Country"
                variant="outlined"
                fullWidth
                value={newChargerData.country}
                onChange={handleNewChargerInputChange}
                select
                style={{ marginBottom: 10 }}
              >
              <MenuItem value="">
                None
            </MenuItem>
            {countryData.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name + ` (${country.code})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ModalComponent>
        <ModalComponent
          open={openAssignModal}
          handleClose={handleCloseAssignModal}
          headerText={"Assign Charger"}
          handleSubmit={handleAssignCharger}
          submitButtonLabel={"Submit"}
        >
          <Autocomplete
          options={allChargers}
          getOptionLabel={(option) => option.serialNumber || ''} // Ensure the serialNumber is present
          value={
            allChargers.find(charger => charger.serialNumber === assignChargerData?.serialNumber) || null
        } // Match value with options
        onChange={(event, newValue) => {
          setAssignChargerData((prevData) => ({
              ...prevData,
              serialNumber: newValue?.serialNumber || prevData.serialNumber // Ensure a valid serialNumber is set
          }));
      }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Serial Number"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 20 }}
            />
          )}
        />
          <FormControl fullWidth>
            {" "}
            <InputLabel id="demo-simple-select-label">Site Host Admin</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={assignChargerData?.siteHostAdmin}
              label="Site Host Admin"
              name="siteHostAdminId"
              onChange={(event) => {
                const siteHostAdminId = event.target.value;
                setSelectedSiteHostAdminId(siteHostAdminId);
                setAssignChargerData({
                  ...assignChargerData,
                  siteHostAdminId: siteHostAdminId,
                  evseStationId: "", // Reset sites when siteHostAdmin changes
                });
              }}
              style={{ marginBottom: 20 }}
            >
             {siteHostAdmins.map((siteHostAdmin) => (
                <MenuItem key={siteHostAdmin.id} value={siteHostAdmin.id}>
                  {siteHostAdmin.firstName} {siteHostAdmin.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            {" "}
            <InputLabel id="demo-simple-select-label">EVSE Station</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="evseStation"
              value={assignChargerData?.evseStationId}
              label="EVSE Station"
              name="evseStationId"
              onChange={handleAssignChargerInputChange}
              style={{ marginBottom: 20 }}
            >
              {evseStations
                .map((station) => (
                  <MenuItem key={station.id} value={station.id}>
                    {station.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </ModalComponent>
        {/* Edit Modal */}
        <ModalComponent
          open={openEditModal}
          handleClose={handleCloseEditModal}
          headerText={"Edit Charger"}
          handleSubmit={handleUpdateCharger} // Assuming this function is implemented for updating charger data
          submitButtonLabel={"Update Charger"}
        >
          <TextField
            id="serialNumber"
            name="serialNumber"
            label="Serial Number"
            variant="outlined"
            fullWidth
            value={newChargerData.serialNumber}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          />
          <TextField
            id="deviceName"
            name="deviceName"
            label="Device Name"
            variant="outlined"
            fullWidth
            value={newChargerData.deviceName}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          />
          <FormControl fullWidth>
            {" "}
            <InputLabel id="demo-simple-select-label">Connector Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newChargerData?.connectorTypeId}
              label="Connector Type"
              name="connectorTypeId"
              onChange={handleNewChargerInputChange}
              style={{ marginBottom: 10 }}
            >
              {connectorTypesData?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {" "}
                  {option?.displayText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            {" "}
            <InputLabel id="demo-simple-select-label">Evse Owner</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="evseOwnerId"
              name="evseOwnerId"
              value={newChargerData?.evseOwnerId}
              label="Evse Owner"
              onChange={handleNewChargerInputChange}
              style={{ marginBottom: 10 }}
            >
              {evseOwnersData.map((owner) => (
                <MenuItem key={owner.id} value={owner.id}>
                  {" "}
                  {owner?.firstName} {owner?.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ModalComponent>
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this charger?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmation} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <ChargerDetailsComponent 
      selectedCharger={selectedChargersDetails.charger} 
      open={selectedChargersDetails.open} 
      handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
      />
    {selectedChargerforMap && (
        <ChargerLocationMapComponent 
          open={Boolean(selectedChargerforMap)}
          handleClose={handleCloseMapModal}
          charger={selectedChargerforMap}
        />
      )}
      </MainLayout>
    </>
  );
};
export default Chargers;


