import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { message } from "antd";
import {
  fetchEvseStationsAPI,
  addEvseStationsAPI,
  editEvseStationsAPI
} from "../../redux/apis/evseStations";
import { fetchSitesAPI } from "../../redux/apis/sites";
import {
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Modal,
  Box,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { Add, Edit, Search, Delete } from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import ModalComponent from "../../components/atoms/ModalComponent";
import StationMapComponent from "../../components/googleMap/EvseStationMapComponent";
import {deleteEVSEStationAPI} from "../../redux/apis/evseStations";
import { useSelector } from "react-redux";
import { fetchUserListAPI, fetchChargerList } from "../../redux/apis/chargers";
import ChargerDetailsComponent from "../../components/chargerDetails/chargerDetailsComponent";
import ChargerLocationMapComponent from "../../components/googleMap/ChargerLocationMapComponent";
import moment from "moment";


function EvseStations() {
  const [addEvseStationModalOpen, setAddEvseStationModalOpen] = useState(false);
  const [editEvseStationsModalOpen, setEditEvseStationsModalOpen] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [evseStationsData, setEvseStationsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [stationName, setStationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStationId, setSelectedStationId] = useState(null);
  const [viewonMap, setviewonMap] = useState(false);
  const [totalCount, setTotalCount] = useState(0); // Total count of items for pagination
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [operatorUsers, setOperatorUsers] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState('');
  const [selectedOperatorId, setSelectedOperatorId] = useState('');
  const [formData, setFormData] = useState({
    name:"",
    address: "",
    city: "",
    state: "",
    areaCode: "",
    siteHostAdminId: "",
    siteHostOperatorId: "",
    lat: "",
    lng: ""
  });
  const [modalPage, setModalPage] = useState(0); // Modal pagination
  const [modalRowsPerPage, setModalRowsPerPage] = useState(10); // Modal pagination
  const [modalTotalChargers, setModalTotalChargers] = useState(0); // Modal pagination
  const [openChargersModal, setOpenChargersModal] = useState(false);
  const [selectedChargerforMap, setSelectedChargerforMap] = useState(null);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEvseStations = async () => {
    try {
      setLoading(true);
      const response = await fetchEvseStationsAPI({
        filter: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      });
      setEvseStationsData(response.list || []);
      setTotalCount(response.totalCount || 0); // Set total count for pagination
    } catch (error) {
      console.error("Error fetching EVSE stations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddStation = async () => {
    try {
      await addEvseStationsAPI(formData);
      message.success("EVSE Station Added");
      handleCloseAddModal(); // Close and reset modal
      getEvseStations(); // Refresh data
    } catch (error) {
      console.error("Error adding EVSE station:", error);
    }
  };

  const handleEditStation = async () => {
    try {
      await editEvseStationsAPI(selectedStation.id, formData);
      message.success("EVSE Station Updated");
      handleCloseEditModal(); // Close and reset modal
      getEvseStations(); // Refresh data
    } catch (error) {
      console.error("Error editing EVSE station:", error);
    }
  };

  const [ChargerSiteMapList, SetChargerSiteMapList] = useState([]);
  const fetchChargerListForSite = async (siteId, isModal = false) => {
    setLoading(true);
    try {
      const chargerList = await fetchChargerList({
        filter: JSON.stringify({ EvseStationId: siteId }),
        page: isModal ? modalPage + 1 : page + 1,
        limit: isModal ? modalRowsPerPage : rowsPerPage
      });
      if(isModal){
        SetChargerSiteMapList(chargerList.list);
        setModalTotalChargers(chargerList.totalCount || 0);
        setOpenChargersModal(true);
      }
    } catch (error) {
      console.error("Error fetching charger list for site:", error);
      return [];
    }
    finally{
      setLoading(false);
    }
  };

  const handleOpenChargersModal = (SiteId) => {
    fetchChargerListForSite(SiteId, true);
  };

  const handleCloseChargersModal = () => {
    SetChargerSiteMapList([]);
    setOpenChargersModal(false);
  };

  const handleCloseChargerModal = () => {
    setOpenChargersModal(false);
  };


  const handleModalChangePage = (event, newPage) => {
    setModalPage(newPage);
  };

  const handleModalChangeRowsPerPage = (event) => {
    setModalRowsPerPage(parseInt(event.target.value, 10));
    setModalPage(0);
  };

  const [selectedChargersDetails, setSelectedChargersDetails] = useState({});

   // Function to open view modal and fetch charger data
   const handleViewCharger = (charger) => {
    setSelectedChargersDetails({
      "charger":charger,
      "open":true
    })
  };

  const handleCloseChargerMapModal = () => {
    setSelectedChargerforMap(null);
  };
  const handleOpenChargerMapModal = (charger) => {
    setSelectedChargerforMap(charger);
  };



  const handleOpenEditModal = (station) => {
    setSelectedStation(station);
    setFormData({
      name: station.name,
      address: station.address,
      city: station.city,
      state: station.state,
      areaCode: station.areaCode,
      siteHostAdminId: station.siteHostAdminId,
      siteHostOperatorId: station.siteHostOperatorId,
      lat: station.lat,
      lng: station.lng,
    });
    setEditEvseStationsModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddEvseStationModalOpen(false);
    setFormData({
      name: "",
      address: "",
      city: "",
      state: "",
      areaCode: "",
      siteHostAdminId: "",
      siteHostOperatorId: "",
      lat: "",
      lng: "",
    });
  };

  const handleCloseEditModal = () => {
    setEditEvseStationsModalOpen(false);
    setFormData({
      name: "",
      address: "",
      city: "",
      state: "",
      areaCode: "",
      siteHostAdminId: "",
      siteHostOperatorId: "",
      lat: "",
      lng: "",
    });
  };

  useEffect(() => {
    getEvseStations();
  }, [page, rowsPerPage, searchQuery]);

  const handleOpenMapModal = (station) => {
    setviewonMap(station);
  };

  const handleCloseMapModal = () => {
    setviewonMap(null);
  };

  const handleDeleteSite = async (selectedStationId) => {
    try {
      await deleteEVSEStationAPI(selectedStationId);
      getEvseStations();
      handleCloseDeleteDialog();
      message.success("EVSE Station deleted successfully");
    } catch (error) {
      console.error("Error deleting EVSE station:", error);
      handleCloseDeleteDialog();
      message.error("Failed to delete EVSE station");
    }
  };

  const handleOpenDeleteDialog = (stationId) => {
 
    setSelectedStationId(stationId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  

    const fetchAdminUsers = async () => {
      try {
        const adminUserList = await fetchUserListAPI({
          limit: 500,
          filter: `{ "userRole": "site_host_admin" }`,
        });
        setAdminUsers(adminUserList?.list || []);
      } catch (error) {
        console.error("Error fetching admin users:", error);
      }
    };

    const fetchOperatorUsers = async () => {
      try {
        const operatorUserList = await fetchUserListAPI({
          limit: 500,
          filter: `{ "userRole": "site_host_operators" }`,
        });
        setOperatorUsers(operatorUserList?.list || []);
      } catch (error) {
        console.error("Error fetching operator users:", error);
      }
    };

    useEffect(() => {
      fetchAdminUsers();
      fetchOperatorUsers();
      console.log(adminUsers, operatorUsers);
    }, []);


  return (
    <MainLayout>
      <Typography
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0rem 0rem 1rem 0rem "}
      >
        <Typography>
          <TextField
            id="search"
            variant="outlined"
            hiddenLabel
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
        </Typography>
        <Typography>
          <ButtonComponent
            label={
              <>
                <Add />{" "}
                <Typography component={"span"}>Add Station</Typography>
              </>
            }
            width={"200px"}
            height={"40px"}
            onClick={() => setAddEvseStationModalOpen(true)}
          />
        </Typography>
      </Typography>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
        <TableContainer
        sx={{
          maxHeight: "55vh",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "8px 8px 0 0",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid #e0e0e0",
          backgroundColor: "#ffffff",
        }}>
          <Table
            sx={{ minWidth: 650, textTransform: "capitalize" }}
            aria-label="simple table"
          >
            <TableHead
               sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr No.</TableCell>
                <TableCell align="center">Site Name</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">No. Of Chargers</TableCell>
                <TableCell align="center">Created By</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
            sx={{
              "& td": {
                border: "1px solid #e0e0e0",
                color: "#555",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#fafafa",
              },
              "& tr:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}>
              {evseStationsData
                .map((station, index) => (
                  <TableRow
                    key={station.id}
                    sx={{
                      "& td, & th": { border: "1px solid #4C4E641F !important" },
                    }}
                  >
                    <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell align="center">{station?.name}</TableCell>
                    <TableCell align="center">
                      {station?.lat && station?.lng ? (
                        <Button onClick={() => handleOpenMapModal(station)} sx={{color:"#007bff", textDecoration:"underline"}}>
                          View On Map
                        </Button>
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell align="center"><a
                          href="javascript:void(0)"
                          onClick={() => handleOpenChargersModal(station.id)}
                          style={{ color: "#007bff", textDecoration: "underline" }}>{station?.chargerCount}</a>
                        </TableCell>
                    <TableCell align="center">
                      {station?.createdByUserFirstName || station?.createdByUserLastName 
                        ? `${station?.createdByUserFirstName ?? ''} ${station?.createdByUserLastName ?? ''}`.trim() 
                        : '--'}
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        <IconButton onClick={() => handleOpenEditModal(station)}>
                          <Edit sx={{color:"#5882E6"}} />
                        </IconButton>
                        <IconButton
                        onClick={() => handleOpenDeleteDialog(station.id)}
                      >
                        <Delete sx={{color:"#DF6465"}} />
                      </IconButton>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            sx={{
              position: "sticky",
              bottom: 0,
              overflowX : "hidden", 
              padding: "0 0.5rem",
              background: "#f5f3f3",
              borderTop: "1px solid #e0e0e0",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "0 0 8px 8px",
            }}
            count={totalCount} // Total count for pagination
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      <ModalComponent
        open={addEvseStationModalOpen}
        handleClose={handleCloseAddModal}
        handleSubmit={handleAddStation}
        title="Add EVSE Station"
        submitButtonLabel={"Submit"}
      >
        <Box className="modal-content">
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              margin="normal"
            />
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              value={formData.city}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
              margin="normal"
            />
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              value={formData.state}
              onChange={(e) => setFormData({ ...formData, state: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Area Code"
              variant="outlined"
              fullWidth
              value={formData.areaCode}
              onChange={(e) => setFormData({ ...formData, areaCode: e.target.value })}
              margin="normal"
            />
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="admin-select-label">Site Host Admin</InputLabel>
              <Select
                labelId="admin-select-label"
                value={formData.siteHostOperatorId}
                onChange={(e) => setFormData({ ...formData, siteHostOperatorId: e.target.value })}
                label="Site Host Admin"
              >
                {adminUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="operator-select-label">Site Host Operator</InputLabel>
              <Select
                labelId="operator-select-label"
                value={formData.siteHostAdminId}
                onChange={(e) => setFormData({ ...formData, siteHostAdminId: e.target.value })}
                label="Site Host Operator"
              >
                {operatorUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Latitude"
              variant="outlined"
              fullWidth
              value={formData.lat}
              onChange={(e) => setFormData({ ...formData, lat: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Longitude"
              variant="outlined"
              fullWidth
              value={formData.lng}
              onChange={(e) => setFormData({ ...formData, lng: e.target.value })}
              margin="normal"
            />
          </Box>
      </ModalComponent>
      <ModalComponent
        open={editEvseStationsModalOpen}
        handleClose={handleCloseEditModal}
        handleSubmit={handleEditStation}
        title="Edit EVSE Station"
        submitButtonLabel={"Update"}
      >
       <Box className="modal-content">
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              margin="normal"
            />
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              value={formData.city}
              onChange={(e) => setFormData({ ...formData, city: e.target.value })}
              margin="normal"
            />
            <TextField
              label="State"
              variant="outlined"
              fullWidth
              value={formData.state}
              onChange={(e) => setFormData({ ...formData, state: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Area Code"
              variant="outlined"
              fullWidth
              value={formData.areaCode}
              onChange={(e) => setFormData({ ...formData, areaCode: e.target.value })}
              margin="normal"
            />
           <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="admin-select-label">Site Host Admin</InputLabel>
                <Select
                  labelId="admin-select-label"
                  value={formData.siteHostAdminId}
                  onChange={(e) => setFormData({ ...formData, siteHostAdminId: e.target.value })}
                  label="Site Host Admin"
                >
                  {adminUsers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.firstName + " " + user.lastName}
                    </MenuItem>
                  ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="operator-select-label">Site Host Operator</InputLabel>
              <Select
                labelId="operator-select-label"
                value={formData.siteHostOperatorId}
                onChange={(e) => setFormData({ ...formData, siteHostOperatorId: e.target.value })}
                label="Site Host Operator"
              >
                {operatorUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Latitude"
              variant="outlined"
              fullWidth
              value={formData.lat}
              onChange={(e) => setFormData({ ...formData, lat: e.target.value })}
              margin="normal"
            />
            <TextField
              label="Longitude"
              variant="outlined"
              fullWidth
              value={formData.lng}
              onChange={(e) => setFormData({ ...formData, lng: e.target.value })}
              margin="normal"
            />
          </Box>
      </ModalComponent>
      <Dialog
              open={openChargersModal}
              onClose={handleCloseChargersModal}
              maxWidth="lg"
            >
              <DialogTitle>View Chargers</DialogTitle>
              <DialogContent>
                <TableContainer 
                sx={{
                  maxHeight: "55vh",
                  overflow: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}
                >
                  <Table>
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 99,
                        "& th": {
                          border: "1px solid #e0e0e0",
                          background: "#F5F3F3",
                          fontWeight: "500",
                          color: "#333",
                        },
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <TableRow>
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">Charge Box ID</TableCell>
                    <TableCell align="center">EVSE Station</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Reg. date</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">charging Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& td": {
                          border: "1px solid #e0e0e0",
                          color: "#555",
                        },
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: "#fafafa",
                        },
                        "& tr:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                      }}
                    >
                      {ChargerSiteMapList.map((charger, index) => (
                    <TableRow key={charger.id}>
                      <TableCell
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleViewCharger(charger)}
                        align="center"
                      >
                        {charger?.serialNumber}
                      </TableCell>
                      <TableCell align="center">{charger?.chargeBoxId}</TableCell>
                      <TableCell align="center">{charger?.evseStationName}</TableCell>
                      <TableCell align="center">{charger?.evseStationAddress}</TableCell>
                      <TableCell align="center">
                      {charger.lat && charger.lng ? (
                      <a href="javascript:void(0)" onClick={() => handleOpenChargerMapModal(charger)} style={{color:"#007bff", textAlign:"center"}}>
                        View On Map
                      </a>
                    ) : (
                      "--"
                    )}
                      </TableCell>
                      <TableCell align="center">
                        {moment(charger?.registeredAt).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {charger?.registeredByUserFirstName}{" "}
                        {charger?.registeredByUserLastName}
                      </TableCell>
                      <TableCell align="center">{charger?.status}</TableCell>
                      <TableCell align="center">{charger?.chargingStatus}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      overflowX : "hidden", 
                      padding: "0 0.5rem",
                      background: "#f5f3f3",
                      borderTop: "1px solid #e0e0e0",
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "0 0 8px 8px",
                    }}
                    count={modalTotalChargers}
                    rowsPerPage={modalRowsPerPage}
                    page={modalPage}
                    onPageChange={handleModalChangePage}
                    onRowsPerPageChange={handleModalChangeRowsPerPage}
                  />
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseChargerModal}>Close</Button>
              </DialogActions>
            </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this EVSE Station?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteSite(selectedStationId)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {viewonMap && (
        <StationMapComponent 
          open={Boolean(viewonMap)}
          handleClose={handleCloseMapModal}
          station={viewonMap}
        />
      )}
      <ChargerDetailsComponent 
      selectedCharger={selectedChargersDetails.charger} 
      open={selectedChargersDetails.open} 
      handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
      />
    {selectedChargerforMap && (
        <ChargerLocationMapComponent 
          open={Boolean(selectedChargerforMap)}
          handleClose={handleCloseChargerMapModal}
          charger={selectedChargerforMap}
        />
      )}
    </MainLayout>
  );
}

export default EvseStations;
