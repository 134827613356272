"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import ButtonComponent from "../../components/atoms/button/button";
import {
  Add,
  Visibility,
  Search as SearchIcon,
} from "@mui/icons-material";
import { fetchSiteHostAdminAPI, addSiteHostAdminAPI } from "../../redux/apis/siteHostAdmin";
import ModalComponent from "../../components/atoms/ModalComponent";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { message } from "antd";
function SiteHostAdmin() {
  const [evseStations, setEvseStationsData] = useState([]);
  const [siteHostAdminData, setSiteHostAdminData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStations, setTotalStations] = useState(0);
  const [totalSiteHostAdmins, setTotalSiteHostAdmins] = useState(0);
  const [modalPage, setModalPage] = useState(0); // Modal pagination
  const [modalRowsPerPage, setModalRowsPerPage] = useState(10); // Modal pagination
  const [modalTotalStations, setModalTotalStations] = useState(0); // Modal pagination
  const [loading, setLoading] = useState(false);
  const [openSiteModal, setOpenSiteModal] = useState(false);
  const [addsiteHostAdminModalOpen, setAddSiteHostAdminModalOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [siteHostAdminFormData, setsiteHostAdminFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    accountNo: "",
    accountType: "",
    bankName: "", 
    bankCode:"",
    description: ""
  });
  const [allStations, setAllStations] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalChangePage = (event, newPage) => {
    setModalPage(newPage);
  };

  const handleModalChangeRowsPerPage = (event) => {
    setModalRowsPerPage(parseInt(event.target.value, 10));
    setModalPage(0);
  };


  const fetchStationsForAdmin = async (siteHostAdminId, isModal = false) => {
    setLoading(true);
    try {
     
      const response = await fetchEvseStationsAPI({
        page: isModal ? modalPage + 1 : page + 1,
        limit: isModal ? modalRowsPerPage : rowsPerPage,
        filter: `{ "siteHostAdminId": "${siteHostAdminId}" }`,
      });
     
      if (isModal) {
        setEvseStationsData(response.list || []);
        setModalTotalStations(response.totalCount || 0);
      } else {
        setEvseStationsData(response.list || []);
        setTotalStations(response.totalCount || 0);
      }
    } catch (error) {
      console.error("Error fetching Stations:", error);
    }
    finally{
      setLoading(false);
    }
  };

 
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setsiteHostAdminFormData({});
  };

  const handleSubmitAddAdmin = async () => {
    try {
      await addSiteHostAdminAPI({
        ...siteHostAdminFormData,
        userType: "site_host",
        userRole: "site_host_admin",
      });
      setAddSiteHostAdminModalOpen(false);
      message.success("Site host admin added successfully");
      fetchSiteHostAdminData();
    } catch (error) {
      console.error("Error adding site host admin:", error);
      message.error("Failed to add site host admin");
    }
  };

  const fetchSiteHostAdminData = async () => {
    setLoading(true);
    try {
      const response = await fetchSiteHostAdminAPI({
        search: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        filter: `{ "userRole": "site_host_admin" }`,
      });
 
      setSiteHostAdminData(response.list || []);
      setTotalSiteHostAdmins(response.totalCount || 0); // Update totalSiteHostAdmins with totalCount from response

    } catch (error) {
      console.error("Error fetching site host admins:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchSiteHostAdminData();
  }, [searchQuery, page, rowsPerPage]);


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenSiteModal = (siteHostAdminId) => {
    fetchStationsForAdmin(siteHostAdminId, true);
    setOpenSiteModal(true);
  };

  const handleCloseSiteModal = () => {
    setOpenSiteModal(false);
  };

 const fetchStations = async () => {
    try {
      const response = await fetchEvseStationsAPI({
        limit: 500,
      });
      setAllStations(response.list || []);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };

  useEffect(() => {
    fetchStations();
  }, [addsiteHostAdminModalOpen]);

  const handleAddModalInputChange = (event) => {
    const { name, value } = event.target;
    setsiteHostAdminFormData({ ...siteHostAdminFormData, [name]: value });
  };


  return (
    <>
      <MainLayout>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem "}
        >
          <Typography>
            <TextField
              id="search"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
          <ButtonComponent
            label={
              <>
                <Add />{" "}
                <Typography component={"span"}>Add Admin</Typography>
              </>
            }
            width={"200px"}
            height={"40px"}
            onClick={() => setOpenAddModal(true)}
          />
        </Typography>
        </Typography>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer
              sx={{
                maxHeight: "65vh",
                overflow: "auto",
                borderRadius: "8px 8px 0 0",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                border: "1px solid #e0e0e0",
                backgroundColor: "#ffffff",
              }}
            >
              <Table sx={{ width: "100%" }}>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Site Host Admin</TableCell>
                    <TableCell align="center">Email Id</TableCell>
                    <TableCell align="center">Mobile No.</TableCell>
                    <TableCell align="center">View Sites</TableCell>
                    <TableCell align="center">No. Of Chargers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                >
                  {siteHostAdminData.map((admin, index) => (
                    <TableRow key={admin.id}>
                      <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell align="center">
                        {admin?.firstName} {admin?.lastName}
                      </TableCell>
                      <TableCell align="center">{admin?.email}</TableCell>
                      <TableCell align="center">{admin?.phoneNumber}</TableCell>
                      <TableCell align="center">
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleOpenSiteModal(admin?.id)}
                          style={{ color: "#007bff", textDecoration: "underline" }}
                        >
                          View Sites
                        </a>
                      </TableCell>
                      <TableCell align="center">{admin?.chargerCount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              sx={{
                position: "sticky",
                bottom: 0,
                overflowX : "hidden", 
                padding: "0 0.5rem",
                background: "#f5f3f3",
                borderTop: "1px solid #e0e0e0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0 0 8px 8px",
              }}
              count={totalSiteHostAdmins}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog
              open={openSiteModal}
              onClose={handleCloseSiteModal}
              maxWidth="lg"
            >
              <DialogTitle>View Sites</DialogTitle>
              <DialogContent>
                <TableContainer 
                sx={{
                  maxHeight: "65vh",
                  overflow: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}>
                  <Table>
                    <TableHead
                     sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                    >
                      <TableRow>
                        <TableCell>Sr. No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>No of chargers</TableCell>
                        <TableCell>Site Host Operator</TableCell>
                        <TableCell>Date Of Registration</TableCell>
                        <TableCell>Created By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                     sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },
                      "& tr:hover": {
                        backgroundColor: "#f1f1f1",
                      },
                    }}
                    >
                      {evseStations.map((station, index) => (
                        <TableRow key={station.id}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{station?.name}</TableCell>
                          <TableCell align="center">{station?.address}</TableCell>
                          <TableCell align="center">{station?.chargerCount}</TableCell>
                          <TableCell align="center">
                            {station?.siteHostOperatorFirstName && station?.siteHostOperatorLastName
                              ? station.siteHostOperatorFirstName + " " + station.siteHostOperatorLastName
                              : "--"}
                          </TableCell>
                          <TableCell align="center">{new Date(station?.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell align="center">{station?.createdByUserFirstName} {station?.createdByUserLastName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      overflowX : "hidden", 
                      padding: "0 0.5rem",
                      background: "#f5f3f3",
                      borderTop: "1px solid #e0e0e0",
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "0 0 8px 8px",
                    }}
                    count={modalTotalStations}
                    rowsPerPage={modalRowsPerPage}
                    page={modalPage}
                    onPageChange={handleModalChangePage}
                    onRowsPerPageChange={handleModalChangeRowsPerPage}
                  />
                </TableContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSiteModal}>Close</Button>
              </DialogActions>
            </Dialog>

       <ModalComponent
       open={openAddModal}
       handleClose={handleCloseAddModal}
       headerText="Add Site Host Admin"
       handleSubmit={handleSubmitAddAdmin}
       submitButtonLabel={"submit"}
     >
       {/* Form for adding a new site */}
       <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={siteHostAdminFormData.firstName}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={siteHostAdminFormData.lastName}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={siteHostAdminFormData.email}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="mobile"
                    name="mobile"
                    label="Mobile"
                    value={siteHostAdminFormData.mobile}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="accountNo"
                    name="accountNo"
                    label="Account Number"
                    value={siteHostAdminFormData.accountNo}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="bankName"
                    name="bankName"
                    label="Bank Name"
                    value={siteHostAdminFormData.bankName}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="bankCode"
                    name="bankCode"
                    label="Bank Code"
                    value={siteHostAdminFormData.bankCode}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={siteHostAdminFormData.description}
                    onChange={handleAddModalInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="actype-label">Account Type</InputLabel>
                    <Select
                      labelId="actype-label"
                      id="actype"
                      name="actype"
                      value={siteHostAdminFormData.actype}
                      onChange={handleAddModalInputChange}
                      label="Account Type"
                    >
                      <MenuItem value="savings">Savings</MenuItem>
                      <MenuItem value="current">Current</MenuItem>
                      <MenuItem value="">None</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
           </ModalComponent>
          
          </>
        )}
      </MainLayout>
    </>
  );
}

export default SiteHostAdmin;
