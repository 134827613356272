import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MainLayout from "../layout/mainLayout";
import MongodbChartExample from "../components/Charts/mongodbChartExample";
import { Card } from "../components/atoms/card/index";
import { Typography, Grid } from "@mui/material";
import dashboardStyles from "./dashboard.module.scss";
import TotalIcon from "../components/icons/totalIcon";
import NumberChip from "../components/atoms/numberChip";
import BusyIcon from "../components/icons/busyIcon";
import OfflineIcon from "../components/icons/offlineIcon";
import AvailableIcon from "../components/icons/availableIcon";
import { ChevronRight } from "@mui/icons-material";
import GoogleMapComponent from "../components/googleMap/googleMapComponent";
import ChargerRegisterIcon from "../components/icons/chargerRegisterIcon";
import ChargerDownIcon from "../components/icons/chargerDownIcon";
import Select from "react-select";
import UploadIcon from "../components/icons/uploadIcon";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../utils/constant";
import { CSVLink } from "react-csv";
import StatusCard from "../components/dashboard/StatusCard";

function Dashboard() {
  const [user, setUser] = useState();
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  ;

  useEffect(() => {
    if (user?.email.includes("admin")) {
      setUserType("ADMIN");
    }
    if (user?.email.includes("sitehost")) {
      setUserType("SITEHOST");
    }
    if (user?.email.includes("evse")) {
      setUserType("EVSE");
    }
  }, [user]);

  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"],
  ];

  return (
    <MainLayout>
      <Typography component={"section"} sx={{ overflowY: "auto" }}>
        <Grid
          display={"flex"}
          gap={"20px"}
          justifyContent={"space-between"}
          flexDirection={{ sm: "column", md: "row", xs: "column", lg: "row" }}
        >
          <StatusCard flex={1} />
          <Card flex={1}>
            <Typography
              component={"h1"}
              fontWeight={"500"}
              fontSize={"1.25rem"}
              marginBottom={"0.75rem"}
            >
              Notifications
            </Typography>
            <Grid
              overflowY={"auto"}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"left"}
                sx={{
                  background: " #FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 4px 20px #A8A8A842",
                  borderRadius: "20px",
                  opacity: "1",
                  padding: "12px 2px 12px 2px",
                  mb: 1,
                  px: "12px",
                }}
              >
                <Typography
                  component={"div"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    component={"span"}
                    color={"#002F69"}
                    sx={{
                      background: "#F3F3F3 0% 0% no-repeat padding-box",
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "1rem",
                    }}
                  >
                  <AvailableIcon color={"#002F69"} />
                  </Typography>
                  <Typography
                    component={"span"}
                    color={"#002F69"}
                    fontWeight={"600"}
                    fontSize={"1rem"}
                  >
                    Charger Busy
                    <br />
                    <Typography
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={"#002F69"}
                    >
                      SRN001 (1313 Cedar Lane) | 4 August 2024 1:04 PM
                    </Typography>
                  </Typography>
                </Typography>
                <Typography marginLeft={"auto"}>
                  <NumberChip number={<ChevronRight />} textColor={"green"} />
                </Typography>
              </Grid>
              <Grid
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"left"}
                sx={{
                  background: " #FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: " 0px 4px 20px #A8A8A842",
                  borderRadius: "20px",
                  opacity: "1",
                  padding: "12px 2px 12px 2px",
                  px: "12px",
                }}
              >
                <Typography
                  component={"div"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Typography
                    component={"span"}
                    color={"#20C85D"}
                    sx={{
                      background: "#FCEBEB 0% 0% no-repeat padding-box",
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "1rem",
                    }}
                  >
                    <ChargerDownIcon />
                  </Typography>
                  <Typography
                    component={"span"}
                    color={"red"}
                    fontWeight={"600"}
                    fontSize={"1rem"}
                  >
                    Charger Down
                    <br />
                    <Typography
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={"black"}
                    >
                    SRN0013 (62194 Talmadge Hill) | 7 August 2024, 08:30 PM
                    </Typography>
                  </Typography>
                </Typography>
                <Typography marginLeft={"auto"}>
                  <NumberChip number={<ChevronRight />} textColor={"red"} />
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid
          xs={12}
          container
          marginTop={3}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Grid
            xs={12}
            sm={12}
            md={5.9}
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 0px 20px #0000000D",
              borderRadius: "20px",
              opacity: "1",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              minHeight: "400px",
            }}
          >
            <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography
               component={"h1"}
               fontWeight={"500"}
               fontSize={"1.25rem"}
               marginBlock={"1rem"}
               >
                Energy Consumed
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <Typography component={"span"} marginRight={"8px"}>
                      {" "}
                      <Select
                        className="select-component"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="color"
                        options={TIME_PERIOD_OPTIONS}
                        placeholder="Time Period"
                      />
                    </Typography>
                    <CSVLink data={csvData}>
                      {" "}
                      <Typography component={"span"} marginRight={"8px"}>
                        <UploadIcon />
                      </Typography>
                    </CSVLink>
                </Typography>
            </Typography>
            <Typography>
                Time Period
             </Typography>
            <Typography>
              <MongodbChartExample />
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={5.9}
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 0px 20px #0000000D",
              borderRadius: "20px",
              opacity: "1",
              padding: "16px",
            }}
          >
            <GoogleMapComponent />
          </Grid>
        </Grid>
      </Typography>
    </MainLayout>
  );
}

export default Dashboard;
