import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import ChargnexLogo from '../../components/icons/chargnexLogo';
import ChargnexBackgroundLogo from '../../components/icons/chargnexBackgroundLogo';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/VpnKey';
import axios from 'axios';
import { InputAdornment } from '@mui/material';

const ResetPassword = () => {
  const [passcode, setPasscode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const payload = {
        code: passcode,
        newPassword,
        confirmPassword,
      };

      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/reset-password`, payload);

      setMessage('Password has been reset successfully.');
      // Optionally, redirect to the login page
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      console.error('Error resetting password:', error);
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#002f69",
            borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
            padding: "40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChargnexLogo width={undefined} height={undefined} />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "40px",
            borderRadius: "19px",
            backgroundColor: "#ffffff",
            maxWidth: "400px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontWeight="500"
            fontSize="30px"
            marginBottom="20px"
            textAlign="center"
          >
            Reset Password
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="passcode"
              label="Passcode"
              name="passcode"
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon color="primary" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="new-password"
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon color="primary" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="confirm-password"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon color="primary" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginBottom: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
            >
              Reset Password
            </Button>
            {message && <Typography marginTop="20px">{message}</Typography>}
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ResetPassword;
