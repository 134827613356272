import React, { useState } from "react";
import ModalComponent from "../atoms/ModalComponent";
import { Typography, Grid, Button, Box } from "@mui/material";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import celebrationAnimation  from "./celebration.json";
import Lottie from 'lottie-react';


// Stripe Checkout Form
function CheckoutForm({ plan }) {
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
    
  
    } catch (error) {
      console.error('Payment failed:', error);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
        sx={{ mt: '1.5rem', width: '100%', padding: '10px', color: "#FFFFFF" }}
      >
        {loading ? 'Processing...' : 'Confirm Payment'}
      </Button>
    </form>
  );
}

// Main Component
function PlanShowModal({ showPlanModal, setShowPlanModal }) {
  const [selectedPlan, setSelectedPlan] = useState(null);

  return (
    <ModalComponent
      open={showPlanModal}
      headerText={"Subscription Plans"}
      showSubmitButton={false}
      handleClose={() => setShowPlanModal(false)}
    >
      <Grid
        container
        spacing={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontSize: '1.2rem',
            fontWeight: '500',
            textAlign: 'center',
            color: '#444',
            marginTop: '1rem',
          }}
        >
          Select a plan and proceed to payment
        </Typography>

        <Grid
          container
          spacing={3}
          sx={{
            mt: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Free Plan */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              background: 'white',
              borderRadius: '12px',
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
              padding: '2rem',
              textAlign: 'center',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: '600',
                marginBottom: '1rem',
                color: '#23CB10',
              }}
            >
              Free Plan
            </Typography>
            <Typography sx={{ marginBottom: '1.5rem', fontSize: '1.8rem', fontWeight: 'bold', color: '#23CB10' }}>
              Free
            </Typography>
            <Typography sx={{ fontSize: '0.95rem', marginBottom: '1.5rem' }}>
              <Typography>Full-Featured device monitoring</Typography>
              <Typography>Limited to 5 devices</Typography>
              <Typography>50 MB Storage in total</Typography>
              <Typography>3 user accounts per Organization</Typography>
              <Typography>Free support</Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#23CB10',
                color: 'white',
                borderRadius: '12px',
                padding: '10px 20px',
              }}
              onClick={() => setSelectedPlan('free')}
            >
              Select Free Plan
            </Button>
          </Grid>

          {/* Paid Plan */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              background: 'white',
              borderRadius: '12px',
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
              padding: '2rem',
              textAlign: 'center',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: '600',
                marginBottom: '1rem',
                color: '#D6BE05',
              }}
            >
              Standard Plan
            </Typography>
            <Typography sx={{ marginBottom: '1.5rem', fontSize: '1.8rem', fontWeight: 'bold', color: '#D6BE05' }}>
              $10/month
            </Typography>
            <Typography sx={{ fontSize: '0.95rem', marginBottom: '1.5rem' }}>
              <Typography>Full-Featured device monitoring</Typography>
              <Typography>50 devices supported</Typography>
              <Typography>150 MB Storage</Typography>
              <Typography>3 users per Organization</Typography>
              <Typography>Full support</Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#D6BE05',
                color: 'white',
                borderRadius: '12px',
                padding: '10px 20px',
              }}
              onClick={() => setSelectedPlan('paid')}
            >
              Buy Now
            </Button>
          </Grid>
        </Grid>

        {/* Stripe Checkout Form */}
        {selectedPlan && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                backgroundColor: 'white',
                padding: '2rem',
                borderRadius: '12px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
                width: '400px',
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                Payment Details for {selectedPlan === 'free' ? 'Free' : 'Paid'} Plan
              </Typography>
             
            </Box>
          </Box>
        )}
      </Grid>
    </ModalComponent>
  );
}

export default PlanShowModal;
