import React from 'react';
import HomeIcon from '../icons/homeIcon';
import EsevIcon from '../icons/esevIcon';
import SitehostsIcon from '../icons/sitehostsIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import SettingsIcon from '../icons/settingsIcon';


const SiteHostAdminSidebarItems = [
  {
    name: "Dashboard",
    icon: <HomeIcon />,
    route: "/home",
  },
  {
    name: "EVSE Stations",
    icon: <EsevIcon />,
    route: "/evse-stations",
  },
  {
    name: "Site Host Operators",
    icon: <SitehostsIcon />,
    route: "/site-host-operator",
  },
  {
    name: "Chargers",
    icon: <ChargersIcon />,
    route: "/chargers",
  },
  {
    name: "Analytics",
    icon: <AnalyticsIcon />,
    route: "/analytics",
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    route: "/settings",
  },
  {
    name: "Help and Support",
    icon: <SettingsIcon />,
    route: "/support",
  },
];

export default SiteHostAdminSidebarItems;
