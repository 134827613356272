import React from 'react';
import HomeIcon from '../icons/homeIcon';
import ChargersIcon from '../icons/chargersIcon';
import EsevIcon from '../icons/esevIcon';
import SettingsIcon from '../icons/settingsIcon';

const SiteHostOperatorSidebarItems = [
  {
    name: "Dashboard",
    icon: <HomeIcon />,
    route: "/home",
  },
  {
    name: "Chargers",
    icon: <ChargersIcon />,
    route: "/chargers",
  },
  {
    name: "EVSE Stations",
    icon: <EsevIcon />,
    route: "/evse-stations",
  },
  {
    name: "Help and Support",
    icon: <SettingsIcon />,
    route: "/support",
  },
];

export default SiteHostOperatorSidebarItems;
