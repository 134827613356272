import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate} from "react-router";
import { Link } from 'react-router-dom'; 
import { InputAdornment } from "@mui/material";
import MailIcon from "../../components/icons/mailIcon";
import AuthLayout from "../../layout/authLayout";
import ChargnexLogo from "../../components/icons/chargnexLogo";
import ChargnexBackgroundLogo from "../../components/icons/chargnexBackgroundLogo";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { message } from 'antd';
import {getLoginUser} from "../../redux/apis/auth";
import { useDispatch } from "react-redux";
import { getRapidLogin } from "../../redux/apis/rapid";


export default function SignIn() {
  const dispatch = useDispatch();
  const location = useLocation(); // Hook to access location state
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [rapidLoginData, setRapidLoginData] = useState({});
  const RapidUserDetails = {
    email: "girish.shetye@trunexa.com",
    password: "GirishS@123"
  }

  const RapidAutoLogin = async (RapidUserDetails) => {
    try {
      const response = await getRapidLogin(RapidUserDetails);
      if(response.status === 200) {
        setRapidLoginData(response.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
};

  useEffect(() => {
    RapidAutoLogin(RapidUserDetails);
  }, []);

  const authapi = `${process.env.REACT_APP_BASE_API_URL}/api/auth/login`;

  // Fetch email from location state if available
  const { state } = location;
  const checkemail = state ? state.email : "";
  const rememberMeFromLocation = state ? state.rememberMe : false;

 // Set initial state based on location state
 useState(() => {
  if (checkemail) {
    setEmail(checkemail);
  }
  if (rememberMeFromLocation) {
    setRememberMe(rememberMeFromLocation);
  }
}, [checkemail, rememberMeFromLocation]);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(authapi, {
        email,
        password
      });

      const { user, session } = response.data;
      const userDetails = {
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName,
        email: response.data.user.email,
        mobile: response.data.user.phoneNumber,
        category: response.data.userRole,
      };
      
      const userKey = `user_${user.id}`;
      const tokenKey = `token_${user.id}`;
      const rememberMeKey = `rememberMe_${user.id}`;
      let userData = {};


      //If remember me is clicked, store data in local storage else store data in session storage.
      if (rememberMe) {
        localStorage.setItem('currentUser', user.id);
        localStorage.setItem(userKey, user.id);
        localStorage.setItem(tokenKey, session.token);
        localStorage.setItem(rememberMeKey, true);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        localStorage.setItem('RapidToken', rapidLoginData.tokens.access.token);
        localStorage.setItem('RapidUser', rapidLoginData.user);

      } else { 
        sessionStorage.setItem('currentUser', user.id);
        sessionStorage.setItem(userKey, user.id);
        sessionStorage.setItem(tokenKey, session.token);
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        sessionStorage.setItem('RapidToken', rapidLoginData.tokens.access.token);
        sessionStorage.setItem('RapidUser', JSON.stringify(rapidLoginData.user));
      }

      const fetchUserData = async () => {
        try {
          userData = await getLoginUser();
          if (rememberMe){
            localStorage.setItem("userRole", userData.userRole);
          }
          else{
            sessionStorage.setItem("userRole", userData.userRole);
          }    
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
      
      // Redirect to dashboard or home page
      navigate("/home"); // Replace with your actual dashboard route
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === "User not found.") {
          message.error("User not found.");
        } else if (error.response.data.message === "Invalid password.") {
          message.error("Invalid password.");
        }
      } else {
        console.error("Login error:", error);
        message.error("An unexpected error occurred.");
      }
    }
  };


  return (
   <AuthLayout>
     <Box
      display={"flex"}
      flexDirection={{ xs: "column", sm: "column", md: "row" }}
      sx={{
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#002f69",
          borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
          padding: "40px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ChargnexLogo width={undefined} height={undefined} />
        <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
          <ChargnexBackgroundLogo />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: "40px",
          borderRadius: "19px",
          backgroundColor: "#ffffff",
          maxWidth: "400px",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontWeight="500"
          fontSize="30px"
          marginBottom="20px"
          textAlign="center"
        >
          Sign in
        </Typography>
        <form onSubmit={onSubmit} noValidate>
          <TextField
            margin="normal"
            fullWidth
            sx={{ marginBottom: "20px" }}
            id="email"
            label="Your Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MailIcon color="#23cb10" />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
          <TextField
            margin="normal"
            fullWidth
            sx={{ marginBottom: "20px" }}
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LockIcon color="#23cb10" />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
          <Grid
            container
            justifyContent="space-between"
            sx={{ marginBottom: "20px" }}
          >
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    value="remember"
                    color="primary"
                  />
                }
                sx={{ height: "21px" }}
                label="Remember me"
              />
            </Grid>
            <Grid item xs textAlign="right" color="secondary.main">
              <Typography component="span"><Link to="/forgot-password">Forgot Password?</Link>
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              marginBottom: "20px",
              color: "white",
              borderRadius: "14px",
              height: "48px",
            }}
          >
            Continue
          </Button>
        </form>
      </Box>
    </Box>
   </AuthLayout>
  );
}
