import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Margin, Menu } from "@mui/icons-material";
import ProfileMenu from "../atoms/profileMenu";
import { useNavigate, useLocation } from "react-router";
import QuickStartGuide from "../quick-start/quickStart";
import {Close} from '@mui/icons-material';



const MainHeader = ({ toggleSidebar, isMobile }) => {
  const [openQuickStart, setOpenQuickStart] = useState(false);

  const handleClickOpen = () => {
    setOpenQuickStart(true);
  };

  const handleClose = () => {
    setOpenQuickStart(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
  // Clear session storage
  sessionStorage.clear();
  localStorage.removeItem("userDetails");
  sessionStorage.removeItem("userDetails");

    const currentUserId = localStorage.getItem("currentUser");
    if (currentUserId) {
      localStorage.removeItem(`user_${currentUserId}`);
      localStorage.removeItem(`token_${currentUserId}`);
      localStorage.removeItem(`rememberMe_${currentUserId}`);
      localStorage.removeItem("currentUser");
    }

    navigate("/login");
  };
  
  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails)


  const HeadingMapper = () => {
    switch (location?.pathname) {
      case "/home":
        return "Home";
      case "/chargers":
        return "Chargers";
      case "/evse-stations":
        return "EVSE Stations";
      case "/device-management/monitor":
        return "Monitor";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/site-host-operator":
        return "Site Host Operators";
      case "/site-host-admin":
          return "Site Host Admins";
      case "/evse-owners":
        return "EVSE Owners";
      case "/analytics":
        return "Analytics";
      case "/settings":
        return "Settings";
    }
  };
  return (
    <>
    <AppBar position="static" sx={{ background: "#fff",color: "#000000", boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)", opacity: 1}}>
      <Toolbar sx={{ overflowX: "hidden" }}>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
          >
            <Menu />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          fontSize={"19px"}
        >
          {HeadingMapper()}
        </Typography>
        {(location.pathname === "/home" || location.pathname === "/chargers") && (
          <Button onClick={handleClickOpen} sx={{color: "#000000", textTransform: "none", mr: 2}}>Quick Start Guide</Button>
        )}
        <ProfileMenu
          name={userDetails.firstName+" "+userDetails.lastName}
          onLogout={handleLogout}
          onMyAccountClick={() => {
            navigate("/settings");
          }}
          role={userDetails.category}
          
         
        />
      </Toolbar>

    </AppBar>

    {/* QuickStartGuide Modal */}
      <Dialog open={openQuickStart} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Quick Start Guide</DialogTitle>
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }} aria-label="close" color="primary" size="large">
        <Close />
      </IconButton>
        <DialogContent>
          <QuickStartGuide  onClose={handleClose} openQuickStartModal={handleClickOpen} />
        </DialogContent>
      </Dialog>

      </>
  );
};

export default MainHeader;
