import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

// API Calls

export const fetchUserPaymentAccounts = async (userId) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.get(`/user/${userId}/user-payment-account`);
      return response.data;
    } catch (error) {
      console.error('Error registering charger:', error);
      throw error;
    }
  };

export const AddUserPaymentAccount = async (userId,data) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.post(`/user/${userId}/user-payment-account`, data);
      return response.data;
    } catch (error) {
      console.error('Error Adding User payment account:', error);
      throw error;
    }
  };

  export const updateUserPaymentAccount = async (userId,data) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.patch(`/user/${userId}/user-payment-account`, data);
      return response.data;
    } catch (error) {
      console.error('Error Updating user payment account:', error);
      throw error;
    }
  };

  export const deleteUserPaymentAccount = async (userId,data) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.delete(`/user/${userId}/user-payment-account`, data);
      return response.data;
    } catch (error) {
      console.error('Error deleting user payment account:', error);
      throw error;
    }
  };