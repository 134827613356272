import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import ChargnexLogo from '../../components/icons/chargnexLogo';
import ChargnexBackgroundLogo from '../../components/icons/chargnexBackgroundLogo';
import MailIcon from '../../components/icons/mailIcon';
import { InputAdornment } from '@mui/material';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [resetLinkSent, setResetLinkSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/forgot-password`, { email });
      setMessage('A reset link has been sent to your email address.');
      setResetLinkSent(true);
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#002f69",
            borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
            padding: "40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChargnexLogo width={undefined} height={undefined} />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "40px",
            borderRadius: "19px",
            backgroundColor: "#ffffff",
            maxWidth: "400px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontWeight="500"
            fontSize="30px"
            marginBottom="20px"
            textAlign="center"
          >
            Forgot Password
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="email"
              label="Your Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon color="#23cb10" />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginBottom: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
            >
              Send Reset Link
            </Button>
            {message && <Typography marginTop="20px">{message}</Typography>}
          </form>
          {resetLinkSent && (
            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
              onClick={() => navigate('/set-password')}
            >
              Go to Reset Password Page
            </Button>
          )}
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
