import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";

function TabComponent({ setActiveTab }) {
  const [value, setValue] = React.useState("account");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Typography>
      <Box
        sx={{ background: "white", width: "100%" }}
        borderRadius={"14px"}
        marginLeft={"1rem"}
        my={"1rem"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="white"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="account"
            label="Account"
            onClick={() => setActiveTab("account")}

          />
          <Tab
            value="user_management"
            label="User Management"
            onClick={() => setActiveTab("user_management")}
          />
        </Tabs>
      </Box>
    </Typography>
  );
}

export default TabComponent;
