import React from "react";
import { Card } from "../atoms/card";
import {
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Paper
} from '@mui/material';
import Pusher from "pusher-js";
import { useState, useEffect } from "react";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { fetchChargerList, getKernelLogs } from "../../redux/apis/chargers";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';



function KernelLog() {

  const [kernelLogs, setKernelLogs] = useState(null);
  const [pusherData, SetPusherData] = useState(null);
  const [EvseStationList, setEvseStationList] = useState([]);
  const [chargerList, setChargerList] = useState([]);
  const [SelectedEvseStation, setSelectedEvseStation] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [SystemStatus, setSystemStatus] = useState({});

  // Fetch group data on component mount
  useEffect(() => {
    const fetchEvseStationsData = async () => {
      try {
        const response = await fetchEvseStationsAPI({ limit: 500 });
        if (response) {
          setEvseStationList(response.list);
        } else {
          console.log('Fetching group list failed');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvseStationsData();
  }, []);

  // Fetch charger data whenever a EVSE Station is selected
  useEffect(() => {
    const fetchChargersData = async () => {
      try {
        if (SelectedEvseStation) {
          const response = await fetchChargerList({ filter: JSON.stringify({ EvseStationId: SelectedEvseStation.id }) });
          setChargerList(response.list);
        } 
      } catch (error) {
        console.error(error);
      }
    };
    fetchChargersData();
  }, [SelectedEvseStation]);

  

  useEffect(() => {
    const fetchKernelLogsData = async () => {
      try {
        if (selectedDevice) {
          const response = await getKernelLogs(
            {  deviceId: selectedDevice.chargeBoxId,
               reset: false,
               type: "LIVE_KERNAL_LOGS"
            }
          );
          setKernelLogs(response.data);
        }
      } catch (error) {
        console.error('Error fetching Kernel Logs:', error);
      }
    };
    fetchKernelLogsData();
  }, [selectedDevice]);

  // Handle selecting a group
  const handleGroupChange = (event) => {
    const newGroup = event.target.value;
    setSelectedEvseStation(newGroup);
    // Clear device list and selected device when group is deselected
    if (newGroup === '') {
      setChargerList([]);
      setSelectedDevice('');
      setSystemStatus({});
    }
  };

  // Handle selecting a device
  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  // Handle clear button click
  const handleClearClick = () => {
    setSelectedEvseStation('');
    setChargerList([]);
    setSelectedDevice('');
    setSystemStatus({});
  };


  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      kernelLogs.map((ele) => `${ele}\n`),
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "kernelLogs.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  //pusher
  useEffect(() => {
    // Initialize Pusher with your app key and cluster
    const pusher = new Pusher("008dfd303d8ce988b26c", {
      cluster: "ap2",
    });;
    // Subscribe to the specific channel
    const channel = pusher.subscribe('CGXHPSG24000104');

    // Bind to the event you expect to receive (e.g., 'new-log-event')
    channel.bind('LIVE_KERNAL_LOGS', (data) => {
      // Handle the received data (assuming it's a new log entry)
      console.log(data+" "+selectedDevice)
      SetPusherData(data);
    });

   
    // Cleanup the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [selectedDevice]);

  console.log(pusherData);

  return (
    <Card
      xs={12}
      md={12}
    >
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <FormControl fullWidth margin="dense" size="small" variant="outlined" sx={{ maxWidth: "200px" }}>
          <InputLabel>Evse Station</InputLabel>
          <Select value={SelectedEvseStation} onChange={(event) => handleGroupChange(event)} >
            <MenuItem value="">None</MenuItem>
            {EvseStationList.map((station) => (
              <MenuItem key={station} value={station}>
                {station.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense" size="small" variant="outlined" disabled={!SelectedEvseStation} sx={{ maxWidth: "200px" }} >
          <InputLabel>Charger</InputLabel>
          <Select value={selectedDevice} onChange={(event) => handleDeviceChange(event)} >
            <MenuItem value="">None</MenuItem>
            {chargerList.map((charger) => (
              <MenuItem key={charger.id} value={charger}>
                {charger.chargeBoxId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          onClick={handleClearClick}
          sx={{
            borderRadius: '50%',
            border: '1px solid #ff5b5b',
            backgroundColor: 'none',
            '&:hover': { backgroundColor: 'none', color: "#ff5b5b" },
            maxWidth: "200px",
            color: '#ff3232',
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>
      
          {kernelLogs !== null && (
            <div
              style={{
                fotntWeight: "bold",
                width: "100px",
                margin: "10px 20px 10px 20px",
              }}
            >
              <Button
                variant="contained"
                onClick={downloadTxtFile}
                style={{
                  textTransform: "none",
                  backgroundColor: "#0fcbd0",
                  color: "white",
                  borderRadius: "10px",
                  height: "25px",
                  padding: "10px",
                }}
              >
                Download
              </Button>
            </div>
          )}
      <div style={{ padding: "20px" }}>
        <Paper
          sx={{
            width: "98%",
            overflow: "auto",
            minHeight: "60vh",
            maxHeight: "70vh",
            padding: "10px",
          }}
        >
          {kernelLogs == null
            ? "No Logs available"
            : kernelLogs.map((iter) => {
                return <div style={{ fontWeight: 300 }}>{iter}</div>;
              })}
        </Paper>
      </div>
    </Card>
  );
}

export default KernelLog;
