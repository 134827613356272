import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

function ProcessTab() {
  const [siteHosts, setSiteHosts] = useState([
    {
      sr_no: 1,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    // Add more initial data as needed
  ]);
  return (
    <Typography component={"div"} padding={"1rem"}>
      <Typography fontSize={"16px"}>
        This list gives an overview over currently running system processes and
        their status.
      </Typography>
      <Typography component={"div"} sx={{mt:"1rem"}}>
        <Table
          sx={{ minWidth: 650, textTransform: "capitalize" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              "& td, & th": { border: "1px solid #4C4E641F !important" },
              background: "#F5F3F3 0% 0% no-repeat padding-box",
            }}
          >
            <TableRow>
              <TableCell align="center">Sr. No</TableCell>
              <TableCell align="left">PID</TableCell>
              <TableCell align="left">Owner</TableCell>
              <TableCell align="left">Command</TableCell>
              <TableCell align="left">CPU Usage (%)</TableCell>
              
              <TableCell align="left">Memory Usage (%)</TableCell>
              <TableCell align="left">Kill</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {siteHosts.map((row) => (
              <TableRow
                key={row.sr_no}
                sx={{
                  "& td, & th": {
                    border: "1px solid #4C4E641F !important",
                  },
                }}
              >
                <TableCell align="center">{row.sr_no}</TableCell>
                <TableCell align="left">{row.site_host}</TableCell>
                <TableCell align="left">{row.no_of_chargers}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Typography>
    </Typography>
  );
}

export default ProcessTab;
