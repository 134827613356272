import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { fetchChargerList } from "../../redux/apis/chargers";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { useDispatch } from "react-redux";
import { setSelectedFilter } from "../../redux/slices/filter/filterSlice";
import { Button, Grid } from "@mui/material";

function FilterComponent() {
  const location = useLocation().pathname;
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);

  const fetchData = async (fetchFunction, mapFunction) => {
    try {
      const response = await fetchFunction({ limit: 500 });
      const dataOptions = response.list.map(mapFunction);
      setOptions(dataOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      switch (location) {
        case "/chargers":
          await fetchData(fetchEvseStationsAPI, station => ({
            value: station.id,
            label: station.name,
          }));
          break;
        default:
          setOptions([]);
          break;
      }
    };

    fetchOptions();
  }, [location]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    dispatch(setSelectedFilter(selectedOption));
  };

  const handleClear = () => {
    setSelectedOption(null);
    dispatch(setSelectedFilter(null));
  };

  // Reset selected option when location changes
  useEffect(() => {
    handleClear();
  }, [location]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", gap: "15px" }}>
            <Select
            options={options}
            placeholder="Select"
            onChange={handleChange}
            value={selectedOption}
            styles={{
              container: (provided) => ({
                ...provided,
                minWidth: '250px',
                maxWidth: '300px',
                zIndex: 999,
              }),
              control: (provided) => ({
                ...provided,
                height: '40px',
              }),
            }}
          />
        <Button
          onClick={handleClear}
          variant="contained"
          sx={{ color: "#fff", borderRadius: "14px 12px 14px 14px" }}
        >
          Clear
        </Button>
      </div>
  );
}

export default FilterComponent;
