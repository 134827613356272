import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Radio,
  Tooltip
} from "@mui/material";
import { Add, Edit, Search, Delete, PlayArrow, Refresh} from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import { fetchChargerList } from "../../redux/apis/chargers";
import MainLayout from "../../layout/mainLayout";
import ModalComponent from "../../components/atoms/ModalComponent";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { createRollout, fetchRollouts, uploadFile , deleteRollout, editRollout, playRapidRollout, getRolloutStats} from "../../redux/apis/rapid";
import Pusher from "pusher-js";
import FileUpload from "../../components/file-uploader/upload";
import { message } from "antd";

const RollOut = () => {
  const [openCreateModal, setCreateOpen] = useState(false);
  const [openEditModal, setEditOpen] = useState(false);
  const [openDeleteModal, setDeleteOpen] = useState(false);
  const [currentRollOut, setCurrentRollOut] = useState(null);
  const [fileUploadData, setFileUploadData] = useState(null);
  const [aritifactName, setAritifactName] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [addRolloutData, setAddRolloutData] = useState({
    name: "",
    description: "",
    group: null,
    type: "",
    artifact: "",
    deviceIds: [], // Initialize deviceIds as an array
  });
  const [loadPercentage, setLoadPercentage] = useState(-1);
  const [rollOutData, setRollOutData] = useState([]);
  const [EvseStations, setEvseStationsData] = useState([]);
  // const [groupList, setGroupList] = useState([]);
  const [chargers, setChargers] = useState([]);
  // const [chargers, setChargers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pusherData, SetPusherData] = useState(null);
  const [showDetailedModal, setShowDetailedModal] = useState(false);
  const [selectedRollout, setSelectedRollout] = useState(null);
  const [detailedStatus, setDetailedStatus] = useState({
    applied: 0,
    completion: 0,
    downloaded: 0,
    extracted: 0,
  });
  const [rolloutStats, setRolloutStats] = useState({});
  const pusher = new Pusher("f196fcb7170d0fdfce1d", {
    cluster: "us2",
  });

  useEffect(() => {
    const user =  JSON.parse(sessionStorage.getItem("RapidUser") || localStorage.getItem("RapidUser"));
    const id = user.parent === null ? user.id : user.parent;
    let channel = pusher.subscribe(id);

    channel.bind("ROLLOUT_STATUS_UPDATE", (data) => {
      console.log("Pusher Data>>>>>>>>>>>>>> "+data);
      if (data.clientId == id) {
        SetPusherData(data.rollout);
      }
    });

    return () => {
      SetPusherData(null);
      pusher.unsubscribe(id);
    };
  }, []);

  const fetchStationsData = async () => {
    try {
      const response = await fetchEvseStationsAPI({ limit: 500 });
      const allSites = response.list || [];
      setEvseStationsData(allSites);
    } catch (error) {
      console.error("Error fetching sites:", error);
    }
  };

  useEffect(() => {
    fetchStationsData();
  }, []);

  const fetchChargerData = async () => {
    if (addRolloutData.EvseStationId) {
      const response = await fetchChargerList({
        filter: JSON.stringify({ EvseStationId: addRolloutData.EvseStationId }),
      });
      setChargers(response.list || []);
    }
  };

  useEffect(() => {
    fetchChargerData();
  }, [addRolloutData.EvseStationId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddRolloutData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const chargeBoxId = value;
  
    setAddRolloutData((prevData) => ({
      ...prevData,
      deviceIds: checked
        ? [...prevData.deviceIds, chargeBoxId] // Add chargeBoxId if checked
        : prevData.deviceIds.filter((id) => id !== chargeBoxId), // Remove chargeBoxId if unchecked
    }));
  };
  
  

  const handleUpdateTypeChange = (event) => {
    setAddRolloutData((prevState) => ({
      ...prevState,
      type: event.target.value,
    }));
  };



  const handleOpenCreateModal = () => {
    setAddRolloutData({
      name: "",
      group: null,
      description: "",
      deviceIds: [],
      artifact: "",
      type: "", // Reset update type
    });
    setCreateOpen(true);
  };

  const handleOpenEditModal = (rollOut) => {
    setSelectedRollout(rollOut);
  
    let fileName;
    if (rollOut.artifact) {
      fileName = new URL(rollOut.artifact).pathname.substring(1);
    }
    setAritifactName(fileName);
    console.log(aritifactName);
  
    setAddRolloutData({
      name: rollOut.name,
      description: rollOut.description,
      group: rollOut.group,
      artifact: rollOut.artifact,
      deviceIds: Array.isArray(rollOut.deviceIds) ? rollOut.deviceIds : [],
      type: rollOut.type,
    });
    setFileUploadData(null);
    setEditOpen(true);
  };
  


  const handleEditSubmit = () => {
    try {
      editRollout(selectedRollout._id, addRolloutData).then(() => {
        setEditOpen(false);
        message.success("Rollout updated successfully");
        setSelectedRollout(null);
  
        // Clear the artifact name
        setAritifactName("");
      
        // Reset the form data for adding or editing rollouts
        setAddRolloutData({
          name: "",
          description: "",
          group: "",
          artifact: "",
          deviceIds: [],
          type: "",
        });
      
        // Clear the file upload data
        setFileUploadData(null);
        fetchRolloutsData();
      }).catch((error) => {
        message.error("Error updating rollout");
        console.error("Error updating rollout:", error);
      });
    } catch (error) {
      message.error("Error updating rollout");
      console.error("Error updating rollout:", error);
      setEditOpen(false);
    }
  };

  const handleCreateSubmit = () => {
        
    const createData = {
      ...addRolloutData
    }
    createRollout(createData).then((response) => {
      setAddRolloutData({
        name: "",
        description: "",
        group: "",
        artifact: "",
        deviceIds: [],  
        type: "", // Reset update type
      });
      setCreateOpen(false);
      message.success("Rollout created successfully");
      fetchRolloutsData();
    }).catch((error) => {
      message.error("Error creating rollout");
      console.error("Error creating rollout:", error);
    });
  };

  const handleCloseCreateModal = () => {
    setAddRolloutData({
      name: "",
      description: "",
      group: "",
      artifact: "",
      deviceIds: [],  
      type: "", // Reset update type
    })
    setCreateOpen(false);
    setChargers([]);

    setChargers([]);
  };


  const handleCloseEditModal = () => {
    // Reset the selected rollout data
    setSelectedRollout(null);
  
    // Clear the artifact name
    setAritifactName("");
  
    // Reset the form data for adding or editing rollouts
    setAddRolloutData({
      name: "",
      description: "",
      group: "",
      artifact: "",
      deviceIds: [],
      type: "",
    });
  
    // Clear the file upload data
    setFileUploadData(null);
  
    // Close the edit modal
    setEditOpen(false);

    // Set chargers List to empty
    setChargers([]);
  };
  

  const handleDelete = (rollout) => {
    console.log(rollout)
    setSelectedRollout(rollout);
    setDeleteOpen(true);
  };

  const confirmDelete = () => {
    try{
      const response = deleteRollout(selectedRollout._id);
      if (response) {
        setAddRolloutData({
          name: "",
          description: "",
          group: "",
          artifact: "",
          deviceIds: [],  
          type: "", // Reset update type
        })
        fetchRolloutsData();
        setDeleteOpen(false);
        message.success("Rollout deleted successfully");
      }
    }catch (error) {
      setDeleteOpen(false);
      message.error("Error deleting rollout");
      console.error("Error deleting rollout:", error);
    }
    
  };

  const cancelDelete = () => {
    setDeleteOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  

  const [chargerpage, setChargerPage] = useState(0);
  const [chargerRowsPerPage, setChargerRowsPerPage] = useState(5);
  const [selectAll, setSelectAll] = useState(false);
  const handleChargerPageChange = (event, newPage) => {
    setChargerPage(newPage);
  };

  const handleChargerRowsPerPageChange = (event) => {
    setChargerRowsPerPage(parseInt(event.target.value, 10));
    setChargerPage(0);
  };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    const newSelected = event.target.checked
      ? chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(device => device.chargeBoxId)
      : [];
    setAddRolloutData((prevState) => ({
      ...prevState,
      deviceIds: newSelected,
    }));
  };


  const fetchRolloutsData = async () => {
    try {
      const response = await fetchRollouts();
      setRollOutData(response.list);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRolloutsData();
  }, []);

  //File upload
  useEffect(() => {
    const formData = new FormData();
    if (fileUploadData === null) {
      setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
      setAritifactName("");
    }
    if (fileUploadData?.size) {
      formData.append("upload", fileUploadData);

      uploadFile(formData, setLoadPercentage)
        .then((res) => {
          if (!res.message) {
            setAddRolloutData((prevVal) => {
              const updatedData = { ...prevVal, artifact: res.location };
              return updatedData;
            });
            setAritifactName(res.key);
          } else {
            message.error("Failed to Upload, Try Again!");
            setFileUploadData(null);
            setAritifactName("");
            setIsFileUploading(false);
            setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Failed to Upload, Try Again!");
          setFileUploadData(null);
          setAritifactName("");
          setIsFileUploading(false);
          setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
        });
    }
  }, [fileUploadData]);

  // const fetchgroupsData = async () => {
  //   try {
  //     const response = await groupDropdown();
  //     if (response) {
  //       let data = response.map((iter) => {
  //         return { value: iter.id, name: iter.name };
  //       });
  //       setGroupList(data);
  //     } else {
  //       console.log("Fetching group list failed");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchchargersData = async () => {
    try{
      if (addRolloutData.group) {
        const response = await fetchChargerList(
          {
            filter : `{"EvseStationId": "${addRolloutData.group}"}`,
          }
        );
        setChargers(response.list);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    fetchchargersData();
  }, [addRolloutData.group]);

  //Play Rollout

  const playRollout = async (rollOutId) => {
    try {
      const response = await playRapidRollout(rollOutId);
      console.log(response);
      if (response) {
        message.success("Rollout Started");
        fetchRolloutsData();

      } else {
        console.log("Failed to play rollout");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetching rollout Stats

  const fetchRolloutStats = async (selectedRollout) => {
    try {
      const response = await getRolloutStats(selectedRollout._id);
      setRolloutStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailsModal = (rollout) => {
    setSelectedRollout(rollout);
    setShowDetailedModal(true);
  };

  useEffect(() => {
    fetchRolloutStats(selectedRollout);
    console.log(rolloutStats);
  }, [selectedRollout]);

  return (
    <MainLayout>
      <div>
        {/* Search Filter and Add/Create Button */}
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem"}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>Create Rollout</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={handleOpenCreateModal}
            />
          </Typography>
        </Typography>

        {/* Create Modal */}
        <ModalComponent
          open={openCreateModal}
          handleClose={handleCloseCreateModal}
          headerText="Create Rollout"
          handleSubmit={handleCreateSubmit}
          submitButtonLabel="Save"
        >
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <TextField
                  label="Rollout Name"
                  name="name"
                  value={addRolloutData?.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={addRolloutData?.description}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} md={8}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                   <Typography ><strong>
                      Upload Artifacts</strong>
                    </Typography>
                    <div>
                      <FileUpload
                        value={aritifactName}
                        setIsFileUploading={setIsFileUploading}
                        callBack={setFileUploadData}
                      />
                    </div>
                  </div>
                </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Group</InputLabel>
                  <Select
                    name="group"
                    value={addRolloutData?.group}
                    onChange={handleChange}
                    label="Group"
                  >
                    {EvseStations.map((group) => (
                      <MenuItem key={group?.id} value={group?.id}>
                        {group?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>               
              <Grid item xs={12}>
                <Typography><strong>Update Type:</strong></Typography>
                <FormControl
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <FormControlLabel
                    value="OS"
                    control={<Radio />}
                    label="OS"
                    checked={addRolloutData.type === "OS"}
                    onChange={handleUpdateTypeChange}
                  />
                  <FormControlLabel
                    value="Application"
                    control={<Radio />}
                    label="Application"
                    checked={addRolloutData.type === "Application"}
                    onChange={handleUpdateTypeChange}
                  />
                </FormControl>
              </Grid>
          <Grid item xs={12}>
            <Typography ><strong>Select chargers:</strong></Typography>
            <TableContainer 
            sx={{
              maxHeight: "55vh",
              overflowX: "hidden",
              overflowY: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
            >
              <Table>
                <TableHead  
                sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}>
                  <TableRow>
                    <TableCell align="center"><Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />Select All</TableCell>
                    <TableCell align="center">Sr. No</TableCell>
                    <TableCell align="center">Charge Box Id</TableCell>
                    <TableCell align="center">Evse Station</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Reg. Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                sx={{
                  "& td": {
                    border: "1px solid #e0e0e0",
                    color: "#555",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#fafafa",
                  },
                  "& tr:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
                >
                  {chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((charger) => (
                    <TableRow key={charger.serialNumber}>
                      <TableCell align="center">
                      <FormControlLabel
                      key={charger.id}
                      control={
                        <Checkbox
                          value={charger.chargeBoxId}
                          checked={Array.isArray(addRolloutData.deviceIds) && addRolloutData.deviceIds.includes(charger.chargeBoxId)}
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                      </TableCell>
                      <TableCell align="center">{charger.serialNumber}</TableCell>
                      <TableCell align="center">{charger.chargeBoxId}</TableCell>
                      <TableCell align="center">{charger.evseStationName}</TableCell>
                      <TableCell align="center">{charger.status}</TableCell>
                      <TableCell align="center">{new Date(charger.registeredAt).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                sx={{
                  position: "sticky",
                  bottom: 0,
                  overflowX : "hidden", 
                  padding: "0 0.5rem",
                  background: "#f5f3f3",
                  borderTop: "1px solid #e0e0e0",
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0 0 8px 8px",
                }}
                count={chargers.length}
                rowsPerPage={chargerRowsPerPage}
                page={chargerpage}
                onPageChange={handleChargerPageChange}
                onRowsPerPageChange={handleChargerRowsPerPageChange}
              />
            </TableContainer>
          </Grid>
        </Grid>
        </ModalComponent>

        <ModalComponent
          open={openEditModal}
          handleClose={handleCloseEditModal}
          headerText="Edit Rollout"
          handleSubmit={handleEditSubmit}
          submitButtonLabel="Save"
        >
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <TextField
                  label="Rollout Name"
                  name="name"
                  value={addRolloutData.name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  value={addRolloutData.description}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={8} md={8}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                   <Typography ><strong>
                      Upload Artifacts</strong>
                    </Typography>
                    <div>
                      <FileUpload
                        value={aritifactName}
                        setIsFileUploading={setIsFileUploading}
                        callBack={setFileUploadData}
                      />
                    </div>
                  </div>
                </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Group</InputLabel>
                  <Select
                    name="group"
                    value={addRolloutData.group}
                    onChange={handleChange}
                    label="Group"
                  >
                    {EvseStations.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography><strong>Update Type:</strong></Typography>
                <FormControl
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <FormControlLabel
                    value="OS"
                    control={<Radio />}
                    label="OS"
                    checked={addRolloutData.type === "OS"}
                    onChange={handleUpdateTypeChange}
                  />
                  <FormControlLabel
                    value="Application"
                    control={<Radio />}
                    label="Application"
                    checked={addRolloutData.type === "Application"}
                    onChange={handleUpdateTypeChange}
                  />
                </FormControl>
              </Grid>
          <Grid item xs={12}>
            <Typography ><strong>Select Chargers:</strong></Typography>
            <TableContainer 
            sx={{
              maxHeight: "55vh",
              overflowX: "hidden",
              overflowY: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
            >
              <Table>
                <TableHead 
                 sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 99,
                  "& th": {
                    border: "1px solid #e0e0e0",
                    background: "#F5F3F3",
                    fontWeight: "500",
                    color: "#333",
                  },
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                }}
                >
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />Select All</TableCell>
                    <TableCell align="center">Sr. No</TableCell>
                    <TableCell align="center">Charge Box Id</TableCell>
                    <TableCell align="center">Evse Station</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Reg. Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody 
                  sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                  >
                  {chargers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((charger) => (
                    <TableRow key={charger.id}>
                      <TableCell align="center">
                      <FormControlLabel
                      key={charger.id}
                      control={
                        <Checkbox
                          value={charger.chargeBoxId}
                          checked={Array.isArray(addRolloutData.deviceIds) && addRolloutData.deviceIds.includes(charger.chargeBoxId)}
                          onChange={handleCheckboxChange}
                        />
                      }
                    />
                      </TableCell>
                      <TableCell align="center">{charger.serialNumber}</TableCell>
                      <TableCell align="center">{charger.chargeBoxId}</TableCell>
                      <TableCell align="center">{charger.evseStationName}</TableCell>
                      <TableCell align="center">{charger.status}</TableCell>
                      <TableCell align="center">{new Date(charger.registeredAt).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                sx={{
                  position: "sticky",
                  bottom: 0,
                  overflowX : "hidden", 
                  padding: "0 0.5rem",
                  background: "#f5f3f3",
                  borderTop: "1px solid #e0e0e0",
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0 0 8px 8px",
                }}
                count={chargers.length}
                rowsPerPage={chargerRowsPerPage}
                page={chargerpage}
                onPageChange={handleChargerPageChange}
                onRowsPerPageChange={handleChargerRowsPerPageChange}
              />
            </TableContainer>
          </Grid>
            </Grid>
        </ModalComponent>

        {/* Details Status Modal */}

        <ModalComponent
          open={showDetailedModal}
          handleClose={() => setShowDetailedModal(false)}
          headerText="Details Status"
          showSubmitButton={false}
        >
        <div>
          <div>
          <Box
                sx={{
                  padding: "16px",
                  border: "1px solid #e0e0e0", // Light grey border
                  borderRadius: "12px", // Slightly rounded corners
                  backgroundColor: "#fff", // White background
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for a lifted look
                  maxWidth: "600px", // Adjust as per your layout
                  margin: "auto", // Center horizontally
                }}
              >
              <Grid container md={12} xs={12} style={{ justifyContent: "center" }}>
                <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Applied</p>
                </Grid>
                <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.applied}</p>
                </Grid>
              </Grid>

              <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Completion</p>
                </Grid>
                <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.completion}%</p>
                </Grid>
              </Grid>

              <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Downloaded</p>
                </Grid>
                <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.downloaded}</p>
                </Grid>
              </Grid>

              <Grid container md={12} xs={12} style={{ justifyContent: "center", marginTop: "8px" }}>
                <Grid item md={3} sm={6} xs={8} style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p style={{ marginBottom: "0px", fontWeight: "bold", color: "#424242" }}>Extracted</p>
                </Grid>
                <Grid item md={3} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <p style={{ marginBottom: "0px", color: "#616161" }}>{detailedStatus.extracted}</p>
                </Grid>
              </Grid>
            </Box>
          </div>
          
      </div>
        </ModalComponent>

        {/* Delete Confirmation Modal */}
        <Dialog
          open={openDeleteModal}
          onClose={() => setDeleteOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this rollout?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete}>Cancel</Button>
            <Button onClick={confirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Rollouts Table */}
        <TableContainer
         sx={{
          maxHeight: "55vh",
          overflowX: "hidden",
          overflowY: "auto",
          borderRadius: "8px 8px 0 0",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid #e0e0e0",
          backgroundColor: "#ffffff",
        }}
        >
          <Table>
            <TableHead  
            sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 99,
                  "& th": {
                    border: "1px solid #e0e0e0",
                    background: "#F5F3F3",
                    fontWeight: "500",
                    color: "#333",
                  },
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                }}
                >
              <TableRow sx={{
                    "& td, & th": { border: "1px solid #4C4E641F !important" },
                  }}>
                <TableCell align="center">Sr. No</TableCell>
                <TableCell align="center">Rollout Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Details Status</TableCell>
                <TableCell align="center">Total chargers</TableCell>
                <TableCell align="center">Created By</TableCell> {/* Added column */}
                <TableCell align="center">Created Date</TableCell> {/* Added column */}
                <TableCell align="center">Actions</TableCell> {/* Added column */}
              </TableRow>
            </TableHead>
            <TableBody
            sx={{
              "& td": {
                border: "1px solid #e0e0e0",
                color: "#555",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#fafafa",
              },
              "& tr:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}>
             {rollOutData.map((rollOut, index) => (
                  <TableRow key={rollOut._id} sx={{
                    "& td, & th": { border: "1px solid #4C4E641F !important" },
                  }}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{rollOut.name}</TableCell>
                    <TableCell align="center">{rollOut.description}</TableCell>
                    <TableCell align="center" onClick={() => handleDetailsModal(rollOut)}><a href="javascript:void(0)">{rollOut.completion}</a></TableCell>
                    <TableCell align="center">{rollOut.totalchargers}</TableCell>
                    <TableCell align="center">{rollOut.account.name}</TableCell>
                    <TableCell align="center">{new Date(rollOut.createdAt).toLocaleString()}</TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {rollOut.status === "CREATED" ? (
                          <IconButton onClick={() => playRollout(rollOut._id)}>
                            <PlayArrow
                              fontSize="large"
                              style={{
                                color: "#075a6a",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        ) : rollOut.status === "FAILED" ? (
                          <IconButton onClick={() => playRollout(rollOut._id)}>
                            <Refresh
                              fontSize="large"
                              style={{
                                color: "#28d1d5",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <Tooltip title="Retry not allowed">
                            <span>
                              <Refresh
                                style={{
                                  color: "#a7afaf",
                                }}
                                fontSize="large"
                                disabled
                              />
                            </span>
                          </Tooltip>
                        )}

                        <IconButton onClick={() => handleOpenEditModal(rollOut)}>
                          <Edit sx={{color: "#5882E6"}}/>
                        </IconButton>

                        <IconButton onClick={() => handleDelete(rollOut)}>
                          <Delete sx={{color: "#DF6465"}}/>
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))} 
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          sx={{
            position: "sticky",
            bottom: 0,
            overflowX : "hidden", 
            padding: "0 0.5rem",
            background: "#f5f3f3",
            borderTop: "1px solid #e0e0e0",
            boxShadow: "0px -2px 10px rgba, 0, 0, 0.1)",
            borderRadius: "0 0 8px 8px",
          }}
          count={rollOutData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </div>
    </MainLayout>
  );
};

export default RollOut;
