import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, responsiveFontSizes , createTheme } from "@mui/material/styles";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard";
import SiteHosts from "./pages/siteHosts/siteHosts";
import Chargers from "./pages/chargers/chargers";
import Analytics from "./pages/analytics/analytics";
import Monitors from "./pages/monitor/monitors";
import OtaUpdates from "./pages/otaUpdates/otaUpdates";
import Settings from "./pages/settings/settings";
import EsevOwners from "./pages/evseOwners/evseOwners";
import ForgotPassword from "./pages/login/forgotPassword";
import ResetPassword from "./pages/login/resetPassword";
import EsevStations from "./pages/evseStations/evseStation";
import Support from "./pages/support/support";
import CheckLogin from "./pages/login/confimLogin";
import SiteHostAdmin from "./pages/siteHostAdmins/siteHostAdmin";
import SiteHostOperator from "./pages/siteHostOperators/siteHostOperator";
import ProtectedRoute from "./components/protection/ProtectedRouteComp";
import "./styles/app.scss"

const App = () => {

  let theme = createTheme({
    palette: {
      primary: {
        main: "#23CB10",
      },
      secondary: {
        main: "#002F69",
      },
      background: {
        default: "#f4f4f4", // Light background color
        paper: "#ffffff", // White background for paper elements
      },
      text: {
        primary: "#333333", // Dark text for readability
        secondary: "#555555", // Lighter text for secondary information
      },
    },
    typography: {
      fontFamily: ["poppins"],
      fontSize: 12,
      h1: {
        fontSize: "1rem", 
        fontWeight: 600,
      },
      h2: {
        fontSize: "0.7rem", 
        fontWeight: 600,
      },
      span: {
        fontSize: "12px", 
      },
      p: {
        fontSize: "12px", 
      },
      div: {
        fontSize: "12px",
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "12px", 
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            transform: "scale(0.8)", // Scaling down the card size
            transformOrigin: "top left", // Ensuring the scaling starts from the top left corner
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: "1rem", 
            fontWeight: 600,
          },
          h2: {
            fontSize: "0.7rem", 
            fontWeight: 500,
          },
          // Add more typography styles as needed
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            padding: "10px 5px",  // Padding for header cells
          },
          body: {
            padding: "10px 5px",  // Padding for body cells
          }
        }
      }
    },
  });
  
  // Optional: Add responsive font scaling
  theme = responsiveFontSizes(theme, { factor: 2.5 });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/check-login" element={<CheckLogin />} />
            <Route path="/set-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* Protected Routes */}
            <Route path="/" element={<Navigate to="/check-login" />} />
            <Route
              path="/home"
              element={<ProtectedRoute component={Dashboard} requiredRoles={["admin", "evse_owner", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/site-hosts"
              element={<ProtectedRoute component={SiteHosts} requiredRoles={["admin", "site_host_admin"]} />}
            />
            <Route
              path="/evse-owners"
              element={<ProtectedRoute component={EsevOwners} requiredRoles={["admin", "evse_owner"]} />}
            />
            <Route
              path="/site-host-operator"
              element={<ProtectedRoute component={SiteHostOperator} requiredRoles={["admin", "site_host_operators"]} />}
            />
            <Route
              path="/site-host-admin"
              element={<ProtectedRoute component={SiteHostAdmin} requiredRoles={["admin", "site_host_admin"]} />}
            />
            <Route
              path="/evse-stations"
              element={<ProtectedRoute component={EsevStations} requiredRoles={["admin", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/chargers"
              element={<ProtectedRoute component={Chargers} requiredRoles={["admin", "evse_owner", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/analytics"
              element={<ProtectedRoute component={Analytics} requiredRoles={["admin", "evse_owner", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/device-management/monitor"
              element={<ProtectedRoute component={Monitors} requiredRoles={["admin", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/device-management/ota-updates"
              element={<ProtectedRoute component={OtaUpdates} requiredRoles={["admin", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/settings"
              element={<ProtectedRoute component={Settings} requiredRoles={["admin", "evse_owner", "site_host_admin", "site_host_operators"]} />}
            />
            <Route
              path="/support"
              element={<ProtectedRoute component={Support} requiredRoles={["admin", "evse_owner", "site_host_admin", "site_host_operators"]} />}
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
