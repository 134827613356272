export const SITE_HOST_OPTIONS = [
  {
    label: "Site, L",
    value: "Site, L",
  },
  {
    label: "Site, Los Angeles",
    value: "Site, Los Angeles",
  },
  {
    label: "Site, Las Vegas",
    value: "Site, Las Vegas",
  },
];

export const TIME_PERIOD_OPTIONS = [
  {
    label: "Today",
    value: "Today",
  },
  {
    label: "Last 1 Week",
    value: "Last 1 Week",
  },
  {
    label: "Last 4 Months",
    value: "Last 4 Months",
  },
  {
    label: "Last 6 Months",
    value: "Last 6 Months",
  },
  {
    label: "Last 1 Year",
    value: "Last 1 Year",
  },
];

export const LOCATION_OPTIONS = [
  // {
  //   label: "Registered",
  //   value: "Registered",
  // },
  // {
  //   label: "Activated",
  //   value: "Activated",
  // },
  {
    label: "Available",
    value: "Available",
  },
  {
    label: "Busy",
    value: "Busy",
  },
  {
    label: "Offline",
    value: "Offline",
  },
  {
    label: "All",
    value: "All",
  },
];
