import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isTokenExpired } from "../../utils/auth"; // Ensure this path is correct

const ProtectedRoute = ({ component: Component, requiredRoles, ...rest }) => {
  const [isValid, setIsValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const validateTokenAndRole = async () => {
      const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");

      if (!currentUserId) {
        setIsChecked(true);
        return;
      }

      const tokenKey = `token_${currentUserId}`;
      const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

      if (!token) {
        setIsChecked(true);
        return;
      }

      const tokenExpired = await isTokenExpired(token);
      if (tokenExpired) {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem("currentUser");
        sessionStorage.removeItem(tokenKey);
        sessionStorage.removeItem("currentUser");
      } else {
        try {
          const userDetails = JSON.parse(localStorage.getItem(`userDetails`) || sessionStorage.getItem(`userDetails`)); 
          const userRole = userDetails?.category;
          if (requiredRoles.includes(userRole)) {
            setIsValid(true);
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      }

      setIsChecked(true);
    };

    validateTokenAndRole();
  }, [requiredRoles]);

  if (!isChecked) {
    return null; 
  }

  if (!isValid) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
