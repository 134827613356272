import { useEffect } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import styles from "./mongodbChartExample.module.scss";
import { BarChart } from "@mui/x-charts/BarChart";

export default function MongodbChartExample() {
  return (
    // <div className={styles.chart_container}>
    <BarChart
      series={[{ data: [100, 44, 24, 34] }]}
      height={400}
      xAxis={[
        {
          data: ["May", "June", "July", "August"],
          scaleType: "band",
        },
      ]}
      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      colors={["#073D81"]}
    />
    // </div>
  );
}
