import React, { useState, useEffect } from "react";
import { Card } from "../atoms/card";
import {
  Typography,
  Grid,
  LinearProgress,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TablePagination,
  Box
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { groupDropdown, fetchDevices, getsystemStatus } from "../../redux/apis/rapid";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { fetchChargerList } from "../../redux/apis/chargers";
import { Flex } from "antd";

function SystemStatus() {
  const Items = [
    "Host Name",
    "Model",
    "Architecture",
    "Firmware Version",
    "Kernel Version",
    "Local Time",
    "Uptime",
    "CPU(%)",
    "Temperature (C)",
  ];

  const [EvseStationList, setEvseStationList] = useState([]);
  const [chargerList, setChargerList] = useState([]);
  const [SelectedEvseStation, setSelectedEvseStation] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [SystemStatus, setSystemStatus] = useState({});

  // Fetch group data on component mount
  useEffect(() => {
    const fetchEvseStationsData = async () => {
      try {
        const response = await fetchEvseStationsAPI({ limit: 500 });
        if (response) {
          setEvseStationList(response.list);
        } else {
          console.log('Fetching group list failed');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvseStationsData();
  }, []);

  // Fetch charger data whenever a EVSE Station is selected
  useEffect(() => {
    const fetchChargersData = async () => {
      try {
        if (SelectedEvseStation) {
          const response = await fetchChargerList({ filter: JSON.stringify({ EvseStationId: SelectedEvseStation.id }) });
          setChargerList(response.list);
        } else {
          console.log('Fetching charger list failed');
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchChargersData();
  }, [SelectedEvseStation]);

  // Fetch system status whenever a charger is selected
  useEffect(() => {
    const fetchSystemStatusData = async () => {
      try {
        if (selectedDevice) {
          const response = await getsystemStatus(selectedDevice.chargeBoxId);
          setSystemStatus(response.data);
        } else {
          console.log('Fetching system status failed');
        }
      } catch (error) {
        console.error('Error fetching system status:', error);
      }
    };
    fetchSystemStatusData();
  }, [selectedDevice]);

  // Handle selecting a group
  const handleGroupChange = (event) => {
    const newGroup = event.target.value;
    setSelectedEvseStation(newGroup);
    // Clear device list and selected device when group is deselected
    if (newGroup === '') {
      setChargerList([]);
      setSelectedDevice('');
      setSystemStatus({});
    }
  };

  // Handle selecting a device
  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  // Handle clear button click
  const handleClearClick = () => {
    setSelectedEvseStation('');
    setChargerList([]);
    setSelectedDevice('');
    setSystemStatus({});
  };

 
  const formatBytesToGB = (bytes) => (bytes / (1024 ** 3)).toFixed(2);

  const totalAvailableGB = SystemStatus?.memory?.totalAvailable 
    ? (SystemStatus.memory.totalAvailable / (1024 ** 3)).toFixed(2) 
    : null;
  const freeGB = SystemStatus?.memory?.free 
    ? (SystemStatus.memory.free / (1024 ** 3)).toFixed(2) 
    : null;
  const bufferedGB = SystemStatus?.memory?.buffered 
    ? (SystemStatus.memory.buffered / (1024 ** 3)).toFixed(2) 
    : null;
  const cachedGB = SystemStatus?.memory?.cached 
    ? (SystemStatus.memory.cached / (1024 ** 3)).toFixed(2) 
    : null;

    const getPercentage = (value) => {
      if (!value || !SystemStatus?.memory?.totalAvailable) return 0;
      return (value / SystemStatus.memory.totalAvailable) * 100;
    };

  const subheadingStyle = {
    fontWeight: '600',
    color: '#343434',
  }
    

  const renderProgressBar = (label, value, amount) => (
      <Box mt="1rem">
        <Typography component="div" mb={1} sx={subheadingStyle}>
          {label}:
        </Typography>
        <Box position="relative">
          <LinearProgress
            variant="determinate"
            value={value || 0}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: value ? '#99cfff' : '#006ccc',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#006ccc',
              },
              padding: '10px',
            }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              color: "#fff",
              position: 'absolute',
              top: '20%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {amount ? `${amount} / ${totalAvailableGB} GB (${Math.round(value)}%)` : 'NA'}
          </Typography>
        </Box>
      </Box>
    );

  return (
    <>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <FormControl fullWidth margin="dense" size="small" variant="outlined" sx={{ maxWidth: "200px" }}>
          <InputLabel>Evse Station</InputLabel>
          <Select value={SelectedEvseStation} onChange={(event) => handleGroupChange(event)} >
            <MenuItem value="">None</MenuItem>
            {EvseStationList.map((station) => (
              <MenuItem key={station} value={station}>
                {station.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense" size="small" variant="outlined" disabled={!SelectedEvseStation} sx={{ maxWidth: "200px" }} >
          <InputLabel>Charger</InputLabel>
          <Select value={selectedDevice} onChange={(event) => handleDeviceChange(event)} >
            <MenuItem value="">None</MenuItem>
            {chargerList.map((charger) => (
              <MenuItem key={charger.id} value={charger}>
                {charger.chargeBoxId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          onClick={handleClearClick}
          sx={{
            borderRadius: '50%',
            border: '1px solid #ff5b5b',
            backgroundColor: 'none',
            '&:hover': { backgroundColor: 'none', color: "#ff5b5b" },
            maxWidth: "200px",
            color: '#ff3232',
          }}
        >
          <ClearIcon />
        </IconButton>
      </div>


      <Card xs={12} md={12} mt="1rem">
        <Typography component={"h1"} fontSize={"22px"} fontWeight={"500"}>
          System
        </Typography>
        <Typography component={"div"} mt={"1rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Hostname: </Typography>{SystemStatus?.system?.hostname}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Model: </Typography>{SystemStatus?.system?.model}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Architecture: </Typography>{SystemStatus?.system?.architecture.join(", ")}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Firmware Version: </Typography>{SystemStatus?.system?.firmwareVersion}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Kernel Version: </Typography>{SystemStatus?.system?.kernalVersion}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Local Time: </Typography>{SystemStatus?.system?.localTime && new Date(SystemStatus.system.localTime * 1000).toLocaleString()}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Uptime: </Typography>{SystemStatus?.system?.upTime}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>CPU (%): </Typography>{SystemStatus?.system?.cpu}
        </Typography>
        <Typography component={"div"} mt={"0.5rem"}>
          <Typography component={"span"} sx={subheadingStyle}>Temperature (C): </Typography>{SystemStatus?.temperature}
        </Typography>
      </Card>
      
      <Card xs={12} md={12} mt="1rem" sx={{ padding: '1rem' }}>
      <Typography component="h1" fontSize="22px" fontWeight="500" mb={2}>
        Memory
      </Typography>
      {renderProgressBar('Total Available', getPercentage(SystemStatus?.memory?.totalAvailable), totalAvailableGB)}
      {renderProgressBar('Free', getPercentage(SystemStatus?.memory?.free), freeGB)}
      {renderProgressBar('Buffered', getPercentage(SystemStatus?.memory?.buffered), bufferedGB)}
      {renderProgressBar('Cached', getPercentage(SystemStatus?.memory?.cached), cachedGB)}
    </Card>

      <Card xs={12} md={12} mt="1rem" >
        <Typography component={"h1"} fontSize={"22px"} fontWeight={"500"}>
          Network
        </Typography>
        {SystemStatus?.network?.map((net, index) => (
          <Card key={index} xs={12} md={12} mt="1rem">
            <Typography component={"h2"} fontSize={"18px"} fontWeight={"600"} mt="1rem">
              {net.name}
            </Typography>
            {net.address_and_netmask_list?.length > 0 && (
              <Typography component={"div"} mt="0.5rem">
                <Typography component={"div"} sx={subheadingStyle}>
                  Address: {net.address_and_netmask_list[0].addresss}
                </Typography>
                <Typography component={"div"} sx={subheadingStyle}>
                  Netmask: {net.address_and_netmask_list[0].net_mask}
                </Typography>
                <Typography component={"div"} sx={subheadingStyle}>
                  MAC: {net.address_and_netmask_list[0].mac}
                </Typography>
              </Typography>
            )}
          </Card>
        ))}
      </Card>

      
      <Card xs={12} md={12} mt="1rem">
        <Typography component={"h1"} fontSize={"22px"} fontWeight={"500"}>
          File Systems
        </Typography>
        <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Filesystem</TableCell>
                <TableCell align="center">Mount Point</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center">Used</TableCell>
                <TableCell align="center">Free</TableCell>
                <TableCell align="center">% Used</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SystemStatus?.mount?.map((fs, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{fs.fs}</TableCell>
                  <TableCell align="center">{fs.mp}</TableCell>
                  <TableCell align="center">{formatBytesToGB(fs.total)}</TableCell>
                  <TableCell align="center">{formatBytesToGB(fs.used)}</TableCell>
                  <TableCell align="center">{formatBytesToGB(fs.free)}</TableCell>
                  <TableCell align="center">{fs.percent}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Card>

    </>
  );
}

export default SystemStatus;
