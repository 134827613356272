import React, { useState } from "react";
import AnalyticsPiChart from "../../components/Charts/AnalyticsPiChart";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import AnalyticsMonthlyRevenueChart from "../../components/Charts/analyticsMonthlyRevenueChart";
import AnalyticsRvsEvsCoChart from "../../components/Charts/analyticsRvsEvsCoChart";
import AnalyticsRvsCoChart from "../../components/Charts/analyticsRvsCoChart";
import AvailableIcon from "../../components/icons/availableIcon";
import Analyticscard from "../../components/analyticsComponents/analyticscard";
import { Card } from "../atoms/card";

function Revenue() {
  const [siteHosts, setSiteHosts] = useState([
    {
      sr_no: 1,
      site_host: "12212",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    // Add more initial data as needed
  ]);
  return (
    <>
      <Grid
        gap={1}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Analyticscard
          icon={<AvailableIcon color={"#23CB10"} />}
          title={"Todays Revenue"}
          content={"$ 100"}
        />
        <Analyticscard
          icon={<AvailableIcon color={"#002F69"} />}
          title={"Total Sessions"}
          content={"$ 100"}
        />
        <Analyticscard
          icon={<AvailableIcon color={"#D6BE05"} />}
          title={"Overall Revenue"}
          content={"$ 100"}
        />
        <Analyticscard
          icon={<AvailableIcon color={"#D90644"} />}
          title={"Total Sessions"}
          content={"$ 100"}
        />
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        justifyContent={"space-evenly"}
        alignItems={"flex-start"}
        marginTop={"0.5rem"}
      >
        <AnalyticsPiChart />
        <AnalyticsMonthlyRevenueChart />
        <AnalyticsRvsEvsCoChart />
        <AnalyticsRvsCoChart />
      </Grid>

      <Card xs={12} md={12} mt="1rem">
        <Typography component={"h1"} fontSize={"22px"} fontWeight={"500"}  mb={"1rem"}>
          Transactions
        </Typography>
        <Typography>
          <Table
            sx={{ minWidth: 650, textTransform: "capitalize" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                "& td, & th": { border: "1px solid #4C4E641F !important" },
                background: "#F5F3F3 0% 0% no-repeat padding-box",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr. No</TableCell>
                <TableCell align="left">Transaction ID</TableCell>
                <TableCell align="left">Charger ID</TableCell>
                <TableCell align="left">End User ID</TableCell>
                <TableCell align="left">IN Time</TableCell>
                <TableCell align="left">OUT Time</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Charging Duration</TableCell>
                <TableCell align="left">Total Meter Value($)</TableCell>
                <TableCell align="left">Platform</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {siteHosts.map((row) => (
                <TableRow
                  key={row.sr_no}
                  sx={{
                    "& td, & th": {
                      border: "1px solid #4C4E641F !important",
                    },
                  }}
                >
                  <TableCell align="center">{row.sr_no}</TableCell>
                  <TableCell align="left">{row.site_host}</TableCell>
                  <TableCell align="left">{row.no_of_chargers}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Typography>
      </Card>
    </>
  );
}

export default Revenue;
